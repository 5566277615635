import React from "react";
import { IconContext } from "react-icons"
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import {
    Person,
    ExitToApp,
} from '@material-ui/icons';
import routes from 'routes';

const sideMenu = (props) => {
    const { fullName, isSidebarVisible } = props;

    return (
        <IconContext.Provider value={{ style: {fontSize: '26px', paddingRight: '10px'}}}>
        <div className={`navbar-default sidebar ${isSidebarVisible ? 'active' : ''}`} role="navigation">
            <div className="sidebar-nav navbar-collapse">
                <ul className="nav" id="side-menu">
                    <li className='Sidebar-Sign-In d-none d-block d-sm-block d-md-none'><Person fontSize='large' /> {`Signed in as:`} <strong>{fullName}</strong></li>
                    {routes && routes().length > 0 && routes().map((route) => (
                        route.show === false ? null : <li key={route.path} className={route.className}><NavLink to={route.path}>{route.icon} {route.title}</NavLink></li>
                    ))}
                    <li className='d-none d-block d-sm-block d-lg-none'><NavLink to='' exact><ExitToApp fontSize='large' /> Logout</NavLink></li>
                </ul>
            </div>

        </div>
        </IconContext.Provider>
    )
};

export default connect(
    state => {
        return {
            isSidebarVisible: state.ui.isSidebarVisible,
        }
    }
)(sideMenu);
