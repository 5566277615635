import {
    postTelehealthAppointment,
} from 'api/zoom';

import {
    POST_TELEHEALTH_REQUEST,
    POST_TELEHEALTH_SUCCESS,
    POST_TELEHEALTH_ERROR
} from './types/zoom';

export const startTelehealthMeeting = appointmentId => {
    return async dispatch => {
        try {
            dispatch({
                type: POST_TELEHEALTH_REQUEST
            });

            const response = await postTelehealthAppointment(appointmentId);

            window.open(response.data, '_blank');

            dispatch({
                type: POST_TELEHEALTH_SUCCESS
            });
        } catch (error) {
            dispatch({
                type: POST_TELEHEALTH_ERROR,
                error,
            });
        }
    };
};