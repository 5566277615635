import {
    FETCH_MESSAGE_LIST_ERROR,
    FETCH_MESSAGE_LIST_SUCCESS,
    FETCH_MESSAGE_LIST_REQUEST,
    FETCH_MESSAGE_BY_ID_REQUEST,
    FETCH_MESSAGE_BY_ID_SUCCESS,
    FETCH_MESSAGE_BY_ID_ERROR,
} from 'redux/actions/types/messages';

const INITIAL_STATE = {
    list: [],
    data: {},
    totalElements: 0,
    isInboxListLoading: false,
    isMessageDetailsPageLoading: false,
}

const messageReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;

    switch (type) {
        case FETCH_MESSAGE_LIST_REQUEST: {
            return {
                ...state,
                isInboxListLoading: true
            }
        }

        case FETCH_MESSAGE_LIST_SUCCESS: {
            return {
                ...state,
                list: payload.data,
                totalElements: payload.recordsTotal,
                isInboxListLoading: false
            }
        }

        case FETCH_MESSAGE_LIST_ERROR: {
            return {
                ...state,
                isInboxListLoading: false,
                error
            }
        }

        case FETCH_MESSAGE_BY_ID_REQUEST: {
            return {
                ...state,
                isMessageDetailsPageLoading: true,
            }
        }

        case FETCH_MESSAGE_BY_ID_SUCCESS: {
            return {
                ...state,
                data: payload,
                isMessageDetailsPageLoading: false,
            }
        }

        case FETCH_MESSAGE_BY_ID_ERROR: {
            return {
                ...state,
                isMessageDetailsPageLoading: false,
                error,
            }
        }

        default:
            return state
    }
}

export default messageReducer;
