import {
    FETCH_NOTIFICATIONS_LIST_ERROR,
    FETCH_NOTIFICATIONS_LIST_REQUEST,
    FETCH_NOTIFICATIONS_LIST_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_ERROR,
    MARK_NOTIFICATION_READ_SUCCESS,
    MARK_NOTIFICATION_READ_ERROR,
} from 'redux/actions/types/notifications';

const INITIAL_STATE = {
    list: [],
    tally: 0,
    isNotificationsListLoading: false,
    error: {},
}

const notificationReducer = (state = INITIAL_STATE, action) => {
    const {type, payload, error} = action;

    switch (type) {
        case FETCH_NOTIFICATIONS_LIST_REQUEST: {
            return { 
                ...state,
                isNotificationsListLoading: true,
            }
        }

        case FETCH_NOTIFICATIONS_LIST_SUCCESS: {
            return {
                ...state,
                isNotificationsListLoading: false,
                list: payload,
            }
        }

        case FETCH_NOTIFICATIONS_LIST_ERROR: {
            return {
                ...state,
                isNotificationsListLoading: false,
                error
            }
        }

        case FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS: {
            return {
                ...state,
                tally: payload,
            }
        }

        case FETCH_NOTIFICATIONS_LIST_TALLY_ERROR: {
            return {
                ...state,
                error
            }
        }

        case MARK_NOTIFICATION_READ_SUCCESS: {
            return {
                ...state,
                tally: payload
            }
        }

        case MARK_NOTIFICATION_READ_ERROR: {
            return {
                ...state,
                error
            }
        }

        default:
            return state
    }
}

export default notificationReducer;
