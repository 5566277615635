import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Modal } from 'antd'; 
import HelpOutline from '@material-ui/icons/HelpOutline';

const ConfirmDialog = ({ 
        isDialogVisible = false, 
        onConfirm, 
        onCancel, 
        okText = 'Confirm', 
        cancelText = 'Cancel' ,
        isConfirmLoading,
        isCancelLoading,
        confirmationText = 'This will confirm'
}) => {
    return (
        <Modal
            visible={isDialogVisible}
            title={'Confirmation'}
            footer={false}
            closable={false}
            centered
        >
            <Container fluid>
                <Row>
                    <Col className='text-center' 
                        xs={{
                            order: 1,
                            span: 12
                        }} md={{
                            order: 1,
                            span: 12
                        }}
                    >
                        <HelpOutline fontSize='large' style={{ fontSize: '6rem', color: '#1890FF' }} />
                        <h3>{confirmationText}</h3>
                    </Col>
                </Row>
            </Container><hr/>
            <Container fluid>
                <Row>
                    <Col className='text-center' 
                        xs={{
                            order: 3,
                            span: 12
                        }} md={{
                            order: 2,
                            span: 6
                        }}>
                        <Button 
                            className='mb-4' 
                            onClick={onCancel} 
                            block 
                            loading={isCancelLoading}
                        >
                            {cancelText}
                        </Button>
                    </Col>
                    <Col 
                        className='text-center' 
                        xs={{
                            order: 2,
                            span: 12
                        }} md={{
                            order: 3,
                            span: 6
                        }}>
                        <Button 
                            className='mb-4' 
                            onClick={onConfirm} 
                            type='primary' 
                            block 
                            loading={isConfirmLoading}
                        >
                            {okText}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
};

export default ConfirmDialog;