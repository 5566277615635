import React, { useEffect, useState } from 'react';
import {
  Button as AntdButton,
  message,
  Spin,
  Checkbox,
  Statistic,
  Progress,
} from 'antd';
import OtpInput from 'react-otp-input';
import moment from 'moment';

import {
  updateFormSubmissions,
  getMostRecentAnswerPrefill,
} from 'api/formSubmissions';

import 'styles/Attachments.scss';

import { Container, Row, Col } from 'react-bootstrap';

import Editor from 'pages/FormBuilder';

import { createOTP, validateOTP } from 'api/otp';
import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListFetch } from 'redux/actions/appointment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { LoadingOutlined } from '@ant-design/icons';

const { Countdown } = Statistic;
const RESEND_OTP_COOLDOWN = 300000;

const FormSubmitModal = ({
  visible,
  setVisible,
  fullScreen,
  activeSubmissionId,
  designId,
  name,
  onSuccess,
  formStatus,
  formDraw,
  setFormDraw,
}) => {
  const nonValuedControls = [
    'Container',
    'Card',
    'Panel',
    'Column',
    'Button',
    'Text',
  ];
  const [excludeValidation, setExcludeValidation] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [isOTPFormShown, setOTPFormShown] = useState(false);
  const [isPageLoading, setPageLoading] = useState(false);
  const [resendOtpOnCooldown, setResendOtpOnCooldown] = useState(false);
  const [formDataValues, setFormDataValues] = useState(null);
  const [formDataErrors, setFormDataErrors] = useState([]);
  const [formDesignData, setFormDesignData] = useState(null);
  const [isFormSubmissionLoading, setFormSubmissionLoading] = useState(false);
  const [formBuilderUtilities, setFormBuilderUtilities] = useState(null);
  const [isFieldsDisabled, setFieldsDisabled] = useState(false);
  const formBuilderState = useSelector((state) => state.formBuilder);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleModalCancel = () => {
    setVisible(false);
  };

  const validateAndGetFormValues = () => {
    let data = [];
    let errors = [];

    if (formBuilderUtilities) {
      const {
        actions: { setProp },
        query,
        nodes,
      } = formBuilderUtilities;

      Object.keys(nodes).map((serializeNodeKey) => {
        const nodeControl = query.getSerializedNodes()[serializeNodeKey];

        function isNodeOrParentNodeHidden(node) {
          if (node.hidden) return true;
          else if (!node.parent) return false;
          else
            return isNodeOrParentNodeHidden(
              query.getSerializedNodes()[node.parent]
            );
        }

        if (!nonValuedControls.includes(nodeControl.displayName)) {
          if (
            nodeControl.props.type === 'datetime' &&
            !nodeControl.props.value
          ) {
            nodeControl.props.value = moment().format('DD/MM/YYYY');
          }

          if (
            nodeControl.props.isRequired &&
            !nodeControl.props.value &&
            !isNodeOrParentNodeHidden(nodeControl)
          ) {
            errors.push(serializeNodeKey);

            setProp(serializeNodeKey, (props) => {
              if (props.error) {
                if (
                  nodeControl.props.name === 'input' &&
                  nodeControl.props.type === 'datetime'
                )
                  props.value = moment().format('DD/MM/YYYY');
                if (nodeControl.props.name === 'select')
                  props.value =
                    props.options &&
                    props.options.length > 0 &&
                    props.options[0].value;
                if (
                  nodeControl.props.name === 'input' &&
                  nodeControl.props.type === 'text'
                )
                  props.value = '';

                props.error.required.isVisible = true;
              }
            });
          } else {
            // if (nodeControl.props.value) {
            setProp(serializeNodeKey, (props) => {
              if (props.error) props.error.required.isVisible = false;
            });

            delete errors[serializeNodeKey];
            // }

            let nodeValue = '';

            if (
              nodeControl.props.value &&
              !isNodeOrParentNodeHidden(nodeControl)
            ) {
              nodeValue = nodeControl.props.value;
            }

            // if (typeof nodeControl.props.value !== 'undefined' || nodeControl.props.value !== null) {
            data.push({
              id: serializeNodeKey,
              name: nodeControl.props.name,
              label:
                nodeControl.props.labelText &&
                nodeControl.props.labelText.replace(':', ''),
              value: nodeValue,
            });
            // }
          }
        }
      });
    }

    return {
      data,
      errors,
    };
  };

  const sendOTP = async () => {
    const { data, errors } = validateAndGetFormValues();

    if (errors && errors.length < 1) {
      setPageLoading(true);
      try {
        const response = await createOTP({
          uniqueId: `fs-${activeSubmissionId}`,
        });

        setOTPFormShown(true);
        setPageLoading(false);
        setFormDataValues(data);
        setFormDataErrors(errors);
      } catch (error) {
        setPageLoading(false);
        console.log(error);
      }
    } else {
      message.error('Please fill in the required field(s).');
    }
  };

  const validateOTPCode = async () => {
    try {
      const response = await validateOTP({
        code: otpValue,
        uniqueId: `fs-${activeSubmissionId}`,
      });

      saveFormAsComplete(formDataValues, formDataErrors);
    } catch (error) {
      console.log(error);
      message.error('Saving form data failed');
    }
  };

  const saveFormAsComplete = async (data, errors) => {
    if (errors && errors.length < 1) {
      const hide = message.loading('Saving form, please wait...', 0);

      try {
        await updateFormSubmissions(
          activeSubmissionId,
          JSON.stringify({
            content: JSON.stringify(data),
            status: 'COMPLETE',
          })
        );

        dispatch(setWillTaskListFetch(true));
        setFormDraw(formDraw + 1);

        if (onSuccess !== undefined) {
          onSuccess();
        }

        hide();

        message.success('Form successfully submitted');

        setVisible(false);
      } catch (error) {
        hide();
        console.log('error', error);
        let errorMessage =
          error.response?.status === 409
            ? 'Form already submitted'
            : 'Form submission failed';
        message.error(errorMessage);
      }
    } else {
      message.error('Please fill in the required field(s).');
    }
  };

  const saveAsDraftClick = async () => {
    const {
      actions: { setProp },
      query,
      nodes,
    } = formBuilderUtilities;

    function isNodeOrParentNodeHidden(node) {
      if (node.hidden) return true;
      else if (!node.parent) return false;
      else
        return isNodeOrParentNodeHidden(
          query.getSerializedNodes()[node.parent]
        );
    }

    let data = [];

    Object.keys(nodes).map((serializeNodeKey) => {
      const nodeControl = query.getSerializedNodes()[serializeNodeKey];

      if (!nonValuedControls.includes(nodeControl.displayName)) {
        let nodeValue = '';

        if (nodeControl.props.value && !isNodeOrParentNodeHidden(nodeControl)) {
          nodeValue = nodeControl.props.value;
        }
        // if (typeof nodeControl.props.value !== 'undefined' || nodeControl.props.value !== null) {
        data.push({
          id: serializeNodeKey,
          name: nodeControl.props.name,
          label:
            nodeControl.props.labelText &&
            nodeControl.props.labelText.replace(':', ''),
          value: nodeValue,
        });
        // }
      }
    });

    const hide = message.loading('Saving form as draft, please wait...', 0);
    hide();

    try {
      await updateFormSubmissions(
        activeSubmissionId,
        JSON.stringify({
          content: JSON.stringify(data),
          status: 'DRAFT',
        })
      );

      hide();

      message.success('Form successfully submitted as draft');
    } catch (error) {
      hide();
      console.log('error', error);
      message.error('Form submission failed');
    }
  };

  useEffect(() => {
    if (!visible) {
      // Reset Form State
      setDisableSubmit(true);
      setOtpValue('');
      setOTPFormShown(false);
      setPageLoading(false);
      setResendOtpOnCooldown(false);
    }
  }, [visible]);

  useEffect(() => {
    if (otpValue.length === 6) {
      validateOTPCode();
    }
  }, [otpValue]);

  useEffect(() => {
    if (!formBuilderUtilities) {
      setFormBuilderUtilities(formBuilderState.utilities);
    }
  }, [formBuilderState.utilities]);

  useEffect(() => {
    if (formBuilderUtilities) {
      const {
        actions: { setProp },
        query,
        nodes,
      } = formBuilderUtilities;

      if (formStatus !== 'COMPLETE') {
        if (formDesignData && formDesignData.prefillEnabled) {
          setFormSubmissionLoading(true);
          (async () => {
            const response = await getMostRecentAnswerPrefill(
              formDesignData.id
            );

            if (response?.data?.data?.content) {
              const formSubmissionContent = JSON.parse(
                response.data.data.content
              );

              Object.keys(nodes).map(async (serializeNodeKey) => {
                const nodeControl =
                  query.getSerializedNodes()[serializeNodeKey];

                if (!nonValuedControls.includes(nodeControl.displayName)) {
                  const formSubmissionItem = formSubmissionContent.find(
                    (jsonFormItem) => jsonFormItem.id === serializeNodeKey
                  );

                  if (nodeControl?.props?.isPrefillable) {
                    await setProp(serializeNodeKey, (props) => {
                      if (!props.value) {
                        props.value = formSubmissionItem.value;
                      }
                    });
                  }
                }
              });
            }

            setFieldsDisabled(false);
            setFormSubmissionLoading(false);
          })();
        }
      } else {
        setFieldsDisabled(true);
      }
    }
  }, [formStatus, formBuilderUtilities, formDesignData]);

  useEffect(() => {
    if (!visible && formBuilderUtilities) {
      clearComponentValues(formBuilderUtilities);
    }
  }, [visible, formBuilderUtilities]);

  const clearComponentValues = (formBuilderUtility) => {
    if (formBuilderUtility) {
      const { actions, query, nodes } = formBuilderUtility;

      if (nodes) {
        Object.keys(nodes).map(async (serializeNodeKey) => {
          const nodeControl = query.getSerializedNodes()[serializeNodeKey];

          if (
            nodeControl &&
            !nonValuedControls.includes(nodeControl.displayName)
          ) {
            if (
              nodeControl.props.hasOwnProperty('value') &&
              nodeControl.props.hasOwnProperty('isVisible')
            ) {
              await actions.setProp(serializeNodeKey, (props) => {
                if (
                  nodeControl.props.displayName === 'Input' &&
                  nodeControl.props.type === 'datetime'
                )
                  props.value = moment().format('DD/MM/YYYY');

                if (nodeControl.props.displayName === 'Select')
                  props.value =
                    props.options &&
                    props.options.length > 0 &&
                    props.options[0].value;
                if (
                  nodeControl.props.displayName === 'Input' &&
                  nodeControl.props.type === 'text'
                )
                  props.value = '';

                nodeControl.props.error.required.isVisible = false;
              });
            }
          }
        });

        setFormBuilderUtilities(null);
      }
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth='lg'
      scroll='paper'
      aria-labelledby='responsive-dialog-title'
      open={visible}
      style={{
        overflowX: 'hidden!important',
      }}
      onClose={() => {
        setVisible(false);
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle
        id='responsive-dialog-title'
        style={{
          borderBottom: '1px solid #ddd',
          background: 'none',
        }}
      >
        {name}
      </DialogTitle>
      <DialogContent
        className='Form-Submission-Body'
        style={{
          minHeight: '50vh',
        }}
      >
        {isPageLoading ? (
          <Row>
            <Col className='text-center'>
              <Spin
                indicator={<LoadingOutlined />}
                style={{ fontSize: 50 }}
                spin
              />
            </Col>
          </Row>
        ) : (
          <>
            <Spin spinning={isFormSubmissionLoading}>
              <div className={isOTPFormShown ? 'd-none' : ''}>
                <Editor
                  id={designId}
                  activeSubmissionFormId={activeSubmissionId}
                  setFormDesignData={setFormDesignData}
                  isFieldsDisabled={isFieldsDisabled}
                />
              </div>
            </Spin>
            <div
              className={`justify-content-center text-center ${
                isOTPFormShown ? '' : 'd-none'
              }`}
            >
              <p className='mt-5'>
                Please enter the OTP sent to your mobile number to submit your
                form.
              </p>
              <OtpInput
                isInputNum={true}
                shouldAutoFocus={true}
                numInputs={6}
                onChange={(value) => {
                  setOtpValue(value);
                }}
                value={otpValue}
                separator={<span> </span>}
                containerStyle={{
                  justifyContent: 'center',
                }}
                inputStyle={{
                  width: '45px',
                  height: '45px',
                  border: 'none',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 5px 0px rgb(55 101 246 / 20%)',
                  fontSize: '2.25rem',
                  margin: '5px',
                }}
              />
              <p className='mt-5'>
                {resendOtpOnCooldown ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <span>You can resend an OTP after</span>
                    <Countdown
                      value={Date.now() + RESEND_OTP_COOLDOWN}
                      onFinish={() => setResendOtpOnCooldown(false)}
                    />
                    <span>seconds</span>
                  </div>
                ) : (
                  <>
                    Did not get one?{' '}
                    <span
                      className='text-primary'
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={async () => {
                        try {
                          await createOTP({
                            uniqueId: `fs-${activeSubmissionId}`,
                          });

                          setResendOtpOnCooldown(true);
                        } catch (err) {
                          console.log(err);
                          message.error(t('message.invalid_otp'));
                        }
                      }}
                    >
                      Tap here to resend
                    </span>
                  </>
                )}
              </p>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions
        style={{
          borderTop: '1px solid #ddd',
        }}
      >
        {isOTPFormShown ? null : (
          <Container fluid>
            <Row>
              {formStatus !== 'COMPLETE' && (
                <Col
                  xs={{
                    span: 24,
                  }}
                  className='ml-4'
                >
                  <Checkbox
                    checked={!disableSubmit}
                    disabled={isFormSubmissionLoading}
                    onChange={(e) => setDisableSubmit(!e.target.checked)}
                  />
                  <span className='ml-4'>
                    I have read and understood the
                    <a target='_blank' href='/collection-notice'>
                      {' '}
                      Collection Notice
                    </a>
                  </span>
                </Col>
              )}
            </Row>
            <Row>
              {formStatus !== 'COMPLETE' && (
                <Col
                  xs={{
                    order: 2,
                    span: 4,
                  }}
                  md={{
                    order: 1,
                    span: 6,
                  }}
                  lg={{
                    order: 1,
                    span: 8,
                  }}
                  xl={{
                    order: 1,
                    span: 10,
                  }}
                  className='text-left mt-4 mb-4'
                >
                  <AntdButton
                    onClick={saveAsDraftClick}
                    disabled={isFormSubmissionLoading}
                  >
                    Save as draft
                  </AntdButton>
                </Col>
              )}
              <Col
                xs={{
                  order: 1,
                  span: 4,
                }}
                md={{
                  order: 2,
                  span: 3,
                }}
                lg={{
                  order: 2,
                  span: 2,
                }}
                xl={{
                  order: 2,
                  span: 1,
                }}
                className='mt-4 mb-4'
              >
                <AntdButton onClick={handleModalCancel}>Close</AntdButton>
              </Col>
              {formStatus !== 'COMPLETE' && (
                <Col
                  xs={{
                    order: 3,
                    span: 4,
                  }}
                  md={{
                    order: 3,
                    span: 3,
                  }}
                  lg={{
                    order: 3,
                    span: 2,
                  }}
                  xl={{
                    order: 3,
                    span: 1,
                  }}
                  className='mt-4 mb-4'
                >
                  <AntdButton
                    className='Form-Craft-Submit'
                    type={disableSubmit ? 'ghost' : 'primary'}
                    onClick={sendOTP}
                    disabled={disableSubmit}
                  >
                    Submit
                  </AntdButton>
                </Col>
              )}
            </Row>
          </Container>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormSubmitModal;
