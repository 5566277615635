import axios from 'axios';
import {getToken, setToken} from './token';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './../constants/index';

export function createAxios (config) {
    const instance = axios.create(config);

    instance.interceptors.request.use(config => {
        // let tokkien = getToken();
        // console.log(tokkien);
        // const token = tokkien;
        const token = Cookies.get(TOKEN_NAME);
        if(token) {
            config.headers.common['Authorization'] = `Bearer ${token}`;
        }
        return config
    })

    instance.interceptors.response.use(response => response,
        error => {
            if(error.response?.status === 401) {
                // keycloak.logout();
                // setToken(null); // ??
                if (Cookies.get(TOKEN_NAME)) {
                    Cookies.remove(TOKEN_NAME);
                    
                }
            }
            return Promise.reject(error);
        })

    return instance;
}