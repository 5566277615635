import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import 'styles/Messages.scss';

import { Button, Card } from 'antd';
import { ChevronLeft, Reply } from '@material-ui/icons';
import { Container, Row, Col } from 'react-bootstrap';

import {
    useDispatch,
    useSelector,
} from 'react-redux';

import {
    fetchMessageById,
} from 'redux/actions/messages';

import {
    setBreadcrumbsText
} from 'redux/actions/ui';

import {
    useTranslation
} from 'react-i18next';

import {
    useAppSelector
} from 'hooks/use-app-selector';

import ReplyModal from 'components/Appointments/Modals/ReplyModal';

const MessageDetails = () => {
    const messageState = useSelector(state => state.messages);
    const { subject, message, appointment, emailAddress, phoneNumber, replyingToMessage } = messageState.data;
    const [ replyModalVisibility, setReplyModalVisibility ] = useState(false);
    const permissions = useAppSelector(state => state.permissions);


    const { id } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(fetchMessageById(id));
        dispatch(setBreadcrumbsText(subject));

        return () => {
            dispatch(setBreadcrumbsText(''));
        }
    }, [id, subject, dispatch])

    return (
        <Card className='Message-Details-Card'>
            { messageState.data && replyModalVisibility && subject &&
                <ReplyModal 
                    visible={replyModalVisibility} 
                    hide={() => setReplyModalVisibility(false) }
                    subject={`${(replyingToMessage ? 'Re: ' : '')} ${subject}`}
                    messageId={id}
                    appointmentId={appointment?.id}
                    onSuccess={() => setReplyModalVisibility(false)}
                />
            }

            <Container fluid>
                <Row>
                    <Col xs={12} lg={6}>
                        <Link to='/inbox'>
                            <Button style={{ marginTop: '5px' }}><ChevronLeft style={{ marginTop: '-10px' }} /> {t('label.back_inbox')}</Button>
                        </Link>
                    </Col>
                </Row><br/>
                <Row>
                    <Col xs={12}>
                        <h3 className='Message-Subject'>{replyingToMessage && 'Re: '}{subject}</h3>
                        {/* <p>From: {emailAddress} | {phoneNumber}</p> */}
                        {/*<hr />*/}
                        <h4 className='Message-Text'>{message}</h4>
                    </Col>
                    <Col className='text-right' xs={12} lg={12}>
                        {permissions.permissions.Inbox.includes('inbox:reply') && <Button onClick={() => setReplyModalVisibility(true) } style={{ marginTop: '10px' }}><Reply style={{ marginTop: '-10px' }} /> {t('label.reply')}</Button>}
                    </Col>
                </Row>
            </Container><br/><br/>
        </Card>
    );
};

export default MessageDetails;
