import { API_BASE_URL } from './config';
import { createAxios } from './axios';

const patientRegistration = createAxios({
    baseURL: `${API_BASE_URL}/patient-registration`
});

export const validateToken = (token:string) => {
    return patientRegistration.get(`/signup/validate-token?token=${token}`);
}

export const registerPatient = (token:string, payload: Object) => {
    return patientRegistration.post(`/signup?token=${token}`, payload);
}