import React, {useEffect, useState} from 'react';
import {API_BASE_URL} from 'api/config';
import {Button, Card, Modal, Empty, message} from 'antd';
import {InputField} from 'components/Controls';
import FileUploader from 'components/Controls/FileUploader';
import ConfirmDialog from 'components/Dialogs/Confirm';
import 'styles/Attachments.scss';

import {uploadAttachment, updateAttachmentDescription, deleteAttachment} from 'api/attachments';

import {Container, Row, Col} from 'react-bootstrap';
import {Attachment, Create, Delete} from '@material-ui/icons';
import {Form, Field, Formik} from 'formik';
import * as Yup from 'yup';

import {useTranslation} from 'react-i18next';

/**
 * TODO
 * this is to replace the modal in AttachmentList
 * will not be implemented right now as it still
 * does not support edit mode
 */
const UploadAttachmentModal = ({setVisible, visible, appointmentId, onSuccess}) => {
  const {t} = useTranslation();

  const [isUploadLoadingVisible, setUploadLoadingVisibility] = useState(false);

  return (
    <Modal
      title={`${t('label.add')} ${t('label.attachment')}`}
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closable={false}
      destroyOnClose={false}
      footer={false}
    >
      <Formik
        initialValues={{}}
        onSubmit={values => {
          setUploadLoadingVisibility(true)
          let formData = new FormData();
          formData.append('description', values.description);
          formData.append('file', values.file);
          formData.append('appointmentId', appointmentId);
          uploadAttachment(formData)
            .then(res => {
              setUploadLoadingVisibility(false)
              setVisible(false);
              onSuccess();
            })
        }}
        validationSchema={Yup.object({
          description: Yup.string().required('Please provide description'),
          file: Yup.mixed().required('Please provide an attachment')
        })}
      >
        <Form>
          <Container fluid>
            <Row>
              <Col xs={{
                order: 1,
                span: 12
              }} style={{
                marginBottom: '20px'
              }}>
                <Field
                  name='file'
                  component={FileUploader}
                />
                <Field
                  id='description'
                  name='description'
                  type='text'
                  label={t('label.description')}
                  placeholder='Enter attachment description'
                  size='large'
                  className='mb-2'
                  component={InputField}
                />
              </Col>
              <Col
                className='text-center'
                xs={{
                  order: 3,
                  span: 12
                }} md={{
                order: 2,
                span: 6
              }}>
                <Button
                  className='mb-4'
                  onClick={() => setVisible(false)}
                  disabled={isUploadLoadingVisible}
                  block>
                  {t('label.cancel')}
                </Button>
              </Col>
              <Col className='text-center' xs={{
                order: 2,
                span: 12
              }} md={{
                order: 3,
                span: 6
              }}>
                <Button
                  className='mb-4'
                  htmlType='submit'
                  type='primary'
                  loading={isUploadLoadingVisible}
                  block>
                  {t('label.save')}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </Formik>
    </Modal>

  );

}

export default UploadAttachmentModal;