// async
export const FETCH_MESSAGE_LIST_REQUEST = 'FETCH_MESSAGE_LIST_REQUEST';
export const FETCH_MESSAGE_LIST_SUCCESS = 'FETCH_MESSAGE_LIST_SUCCESS';
export const FETCH_MESSAGE_LIST_ERROR = 'FETCH_MESSAGE_LIST_ERROR';

export const FETCH_MESSAGE_BY_ID_REQUEST = 'FETCH_MESSAGE_BY_ID_REQUEST';
export const FETCH_MESSAGE_BY_ID_SUCCESS = 'FETCH_MESSAGE_BY_ID_SUCCESS';
export const FETCH_MESSAGE_BY_ID_ERROR = 'FETCH_MESSAGE_BY_ID_ERROR';


// non-async