import React from "react";
import { Paper } from "@material-ui/core";
import { Container as BsContainer } from 'react-bootstrap';

import { useNode, useEditor } from '@craftjs/core';

const Container = ({background, padding = 10, children}) => {
    const { 
        connectors: { connect, drag },
    } = useNode();
    const { query } = useEditor();

    return (
        <Paper 
            className={query.getOptions().enabled && 'With-Mark'} 
            ref={ref => {
                connect(drag(ref));
            }} 
            style={{
                margin: "5px 0", 
                height: 'auto', 
                padding: `${padding}px`, 
                border: 'none'
            }}
        >
            <BsContainer>
                {children}
            </BsContainer>
        </Paper>
    );
};

Container.craft = {
    displayName: 'Container'
}

export default Container;
