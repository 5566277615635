import React, { useState } from 'react';
import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';
import { FieldComponent } from 'interfaces';
import { getFormFieldsDefaultValues } from 'helpers/form';
import {
    InputField,
    SelectField,
    DatePickerField,

} from 'components/Controls';
import {
    Select
} from 'antd';
import { Button, Checkbox, Modal } from 'antd';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";

const { Option } = Select;

interface FormProps {
    fields: FieldComponent[];
    submitButtonText?: string;
    isSubmitButtonLoading?: boolean,
    hasTermsAndConditions?: boolean,
    onSubmit?: (values?:any) => void;
}

export default (props: FormProps) => {
    const {
        fields,
        submitButtonText,
        onSubmit,
        isSubmitButtonLoading,
        hasTermsAndConditions
    } = props;

    const [isTermsAndConditionsChecked, setTermsAndConditions] = useState(false);

    const history = useHistory();

    const buildValidationSchema = (fields: FieldComponent[]):any => {
        let schema:any = {};

        if (fields && fields.length > 0) {
            fields.map(field => {
                if (field.hasOwnProperty('validation')) {
                    if (field.validation && field.validation.length > 0) {
                        const YupObjectString = Yup.string();
                        field.validation.map(fieldValidation => {
                            if (fieldValidation.type === 'required') {
                                schema[field.id] = YupObjectString.required(fieldValidation.message);
                            }
                        })
                    }
                }
            })
        }

        return schema;
    }

    const getFieldComponent = (componentType: string) => {
        switch(componentType) {
            case 'text': 
                return InputField
            case 'dropdown':
                return SelectField
            case 'date':
                return DatePickerField
            default:
                return null
        }
    }

    return  <>
                <Formik
                    initialValues={getFormFieldsDefaultValues(fields)}
                    onSubmit={values => {
                        if (onSubmit) {
                            onSubmit(values);
                        }
                    }}
                    validationSchema={Yup.object().shape(buildValidationSchema(fields))}
                >
                    <Form>
                        <Row>
                            {fields 
                                && fields.length > 0 
                                    && fields.map(field => {
                                        const {
                                            id,
                                            name,
                                            label,
                                            placeholder,
                                            size,
                                            type,
                                            items
                                        } = field;
                                        
                                        const fieldComponent: any = getFieldComponent(type);

                                        let FieldComponentElement: any = <Field
                                                id={id}
                                                name={name}
                                                placeholder={placeholder}
                                                size={size}
                                                label={label}
                                                component={fieldComponent}
                                            />;

                                        if (type === 'dropdown') {
                                            FieldComponentElement = <Field
                                                                        id={id}
                                                                        name={name}
                                                                        placeholder={placeholder}
                                                                        size={size}
                                                                        label={label}
                                                                        component={fieldComponent}
                                                                    >
                                                                        {items
                                                                            && items.length > 0 
                                                                            && items.map(item => {
                                                                                const { value, displayText } = item;
                                                                                return <Option value={value}>{displayText}</Option>
                                                                        })}
                                                                    </Field>
                                        }

                                        return (
                                                <Col
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    {FieldComponentElement}
                                                </Col>
                                        );
                            })}
                            {hasTermsAndConditions && <Col xs={12} className='text-center'>
                                <Checkbox onChange={e => {
                                    const isCheckboxChecked = e.target.checked;

                                    if (isCheckboxChecked) {
                                        setTermsAndConditions(true);
                                    } else {
                                        setTermsAndConditions(false);
                                    }
                                }}>I agree to the <Button className='TNC-Link-Button' type="link" onClick={() => {
                                    window.open('/collection-notice');
                                }}>terms and conditions</Button></Checkbox>
                            </Col>}
                            <Col
                                xs={12}
                            >
                                <Button
                                    htmlType='submit'
                                    type='primary'
                                    className='mt-4'
                                    loading={isSubmitButtonLoading}
                                    disabled={hasTermsAndConditions && !isTermsAndConditionsChecked}
                                    block
                                >
                                    {submitButtonText ? submitButtonText : 'Submit'}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Formik>
            </>
}