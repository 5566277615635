import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card } from 'antd';
import { FcMindMap, FcOvertime, FcPhone } from 'react-icons/fc';
import { MdPhone } from 'react-icons/md';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

function AppointmentInfo({ appointment }) {
  
   const [items, setItems] = useState([]);
   const { t } = useTranslation();

   useEffect(() => {
    setItems([
      {
        heading: t('label.date_time'),
        // detailText: <>{moment(appointment.appointmentDate).format("LL")} {moment().set('hour', 8).format('h A')}</>,
        detailText: moment(appointment.appointmentDateTime).format(
          'DD MMM YYYY HH:mm a'
        ),
        icon: <FcOvertime style={{ width: 48, height: 48 }} />,
      },
      {
        heading: t('label.clinic'),
        detailText: appointment.locationName,
        icon: <FcMindMap style={{ width: 48, height: 48 }} />,
      },
      {
        heading: t('label.phone_number'),
        detailText: appointment.locationContactNumber ? appointment.locationContactNumber : 'No contact number',
        icon: <MdPhone style={{ color: '#fff', width: 48, height: 48 }} />,
      },
    ]);
  }, [appointment]);

  return (
    <>
      {/* <Card className="Appointment-Detail-Card Card-Grids Appointment-Card"> */}
      <Container className='Appointment-Info-Container' fluid>
        <Row>
          {items &&
            items.length > 0 &&
            items.map((item, i) => (
              <Col key={i} xs={12} lg={6} xl={4}>
                <Card
                  key={i}
                  className="Card-Gradient-Primary"
                >
                  <Container fluid>
                    <Row>
                      <Col xs={9} md={10} lg={10}>
                        <h4 className="Appointment-Detail-Title">
                          <strong className='text-uppercase'>{item.heading}</strong>
                        </h4>
                        <h4 className="Appointment-Detail-Text">
                          {item.detailText}
                        </h4>
                      </Col>
                      <Col xs={3} md={2} lg={2}>
                        {item.icon}
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            ))}
        </Row>
        </Container>
      {/* </Card> */}
    </>
  );
}

export default AppointmentInfo;
