import React, {FunctionComponent, useState} from "react";
import {Formik, Field, Form} from "formik";
import * as Yup from 'yup';
import {Row, Col} from "react-bootstrap";
import InputField from "components/Controls/InputField";
import {Button, message} from 'antd';
import {useTranslation} from "react-i18next";
import OTPComponent from './../OTPComponent/index';
import {login} from "../../../../api/login";

interface MobileAuthenticationComponentProps {

}


const MobileAuthenticationComponent: FunctionComponent<MobileAuthenticationComponentProps> = (
    props
) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPComponent, setShowOTPComponent] = useState(false);
    const [mobileNumber, setMobileNumber] = useState('');
    return (
        <Formik
            initialValues={{
                mobileNumber: "",
            }}
            validationSchema={Yup.object({
                mobileNumber: Yup.string().required(t("message.mobile_no_required")),
            })}
            onSubmit={(values) => {
                const payload = {
                    contactNumber: values.mobileNumber,
                };
                setMobileNumber(values.mobileNumber)
                message.destroy();
                login(payload)
                    .then(e => {

                        console.log(e)
                        setShowOTPComponent(true);
                    })
                    .catch(e => {
                        console.log(e.response)
                        if (e.response && e.response.status === 500) {
                            message.error("An error has occurred. Please try again later.")
                        } else {
                            message.error(e.response ? e.response.data.message : 'Something went wrong.')
                        }
                        // setIsLoading(false)
                    })
                // completeAddPatientUser(payload, uniqueId)
                //   .then((response) => {
                //     message.success("Successful");

                //     setTimeout(() => {
                //       keycloak.login({
                //         redirectUri: window.location.href.replace(signupUri, ""),
                //       });
                //     }, 2000);
                //   })
                //   .catch((err) => {
                //     const response = err.response;
                //     if (response && response.data && response.status === 409) {
                //       message.error("Username already exist");
                //     } else {
                //       console.log(response);
                //       message.error("Something went wrong");
                //     }
                //   })
                //   .finally(() => setIsLoading(false));
            }}
        >
            {showOTPComponent ? <OTPComponent mobileNumber={mobileNumber}/> : <Form>
                <Row className="justify-content-center">
                    <Col xs={12} sm={4}>
                        <p className="mt-5">Log In</p>
                        <Field
                            id="mobileNumber"
                            name="mobileNumber"
                            label=""
                            placeholder={t("placeholder.enter_mobile_no")}
                            component={InputField}
                            autocomplete="off"
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center my-5">
                    <Col xs={8} sm={3}>
                        <Button
                            htmlType="submit"
                            loading={isLoading}
                            type="primary"
                            block
                        >
                            Log In
                        </Button>
                    </Col>
                </Row>
                {/* <div className="Content-Footer">
          <Row>
            <Col>
              <Button
                htmlType="submit"
                loading={isLoading}
                type="primary"
                block
              >
                Sign Up
              </Button>
            </Col>
          </Row>
          <div className="Sub-Footer">
            <Row>
              <Col>
                Already have an account?{" "}
                <Link to="#" onClick={() => (window.location.href = "/")}>
                  Login here
                </Link>
              </Col>
            </Row>
          </div>
        </div> */}
            </Form>}
        </Formik>
    );
};

export default MobileAuthenticationComponent