import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const attachmentApi = createAxios({
    baseURL: API_BASE_URL + '/attachments'
});


export const uploadAttachment = (payload) => {
    return attachmentApi.post(``, payload);
};

export const deleteAttachment = attachmentId => {
    return attachmentApi.delete(`/${attachmentId}`);
};

export const updateAttachmentDescription = (attachmentId, payload) => {
    return attachmentApi.patch(`/${attachmentId}/description`, payload);
};

export const getAttachmentDataTable = params => {
    return attachmentApi.get(``,{
        params
    });
}

export const getAttachmentUrl = attachmentId => {
    return attachmentApi.get(`/${attachmentId}/url`);
}
