import React from "react";
import CRUDDataTable from "../../components/DataTable/CRUDDataTable";
import {fetchAll, openCollateral} from "../../api/resources";
import {Link} from "react-router-dom";


const Resources = () => {
  const handleDownloadPreviewLink = async (collateral) => {
    openCollateral(collateral.id, 'url')
      .then(res => {
        window.open(res.data);
      });
  }
  const columns = [
    {
      title: <strong>Name</strong>,
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record, value) => {
        return <Link onClick={() => handleDownloadPreviewLink(record)}>{text}</Link>;
      }
    },
    {
      title: <strong>Description</strong>,
      dataIndex: "description",
      key: "description",
      sorter: true,
    },
    {
      title: <strong>URL</strong>,
      dataIndex: "url",
      key: "url",
      sorter: true,
    }
  ];

  return (
    <CRUDDataTable
      showTitle={false}
      fetchDataFunction={async (params) => await fetchAll(params)}
      entity="collateral"
      columns={columns}
      highlightRow={(rec, i) => {
        return [4, 5].includes(i);
      }}
    />
  );
}
export default Resources;