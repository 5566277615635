import { API_BASE_URL } from './config';
import { createAxios } from './axios';

const patientSettings = createAxios({
  baseURL: API_BASE_URL + '/settings',
});

export const hideSettings = () => {
  const params = {
    category: 'Patient Portal',
    dataType: 'BOOLEAN',
    name: 'HIDE_SETTINGS',
    defaultValue: 'true',
  };

  return patientSettings.get('/search', { params });
};
