import { PermissionsAction, PermissionsState, PERMISSIONS_ACTIONS } from './../types/permissions-types/index';

  const initialState: PermissionsState = {
        permissions: {
          "Appointments": [
            "appointment:confirm",
            "appointment:decline",
            "appointment:telehealth",
            "appointment:messages:send",
            "appointment:messages:reply"
          ],
          "Chatbot": [],
          "Inbox": [
            "inbox:reply"
          ],
          "Forms": [],
          "Resources": [],
          "Tasks": [],
          "Settings": [
            "settings:update-preferred-language"
          ],
        }
  };
  
  export default function permissionsReducer(
    state = initialState,
    action: PermissionsAction
  ): PermissionsState {
    const { type, payload } = action;
    switch (type) {
      case PERMISSIONS_ACTIONS.SET_PERMISSIONS:
        return {
          ...state,
          ...payload,
        };
      default:
        return { ...state };
    }
  }
  