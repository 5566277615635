import React, {useEffect} from 'react';
import {Route} from "react-router";
import {useAuthContext} from "../hooks";

const AuthenticatedRoute = ({ onAuthenticated, onUnauthenticated, ...props }) => {
    const isAuthenticated = useAuthContext();
    return (
        <Route {...props}>
            {isAuthenticated == false && onUnauthenticated()}
            {isAuthenticated && onAuthenticated()}
            {/* do nothing if isAuthenticated is null*/}
        </Route>
    );
}

export default AuthenticatedRoute;