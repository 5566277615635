import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "label.upcoming_appointments": 'Upcoming Appointments',
      "label.past_appointments": "Past Appointments",
      "label.inbox": "Inbox",
      "label.logout": "Logout",
      "label.account": "Account",
      "label.view_details": "View Details",
      "label.confirm": "Confirm",
      "label.decline": "Decline",
      "label.start_telehealth": "Start Telehealth",
      "label.date_time": "Date / Time",
      "label.clinic": "Clinic",
      "label.phone_number": "Clinic Phone number",
      "label.edit": "Edit",
      "label.add": "Add",
      "label.delete": "Delete",
      "label.cancel": "Cancel",
      "label.ok": "Ok",
      "label.back": "Back",
      "label.save": "Save",
      "label.reply": "Reply",
      "label.next": "Next",
      "label.skip": "Skip",
      "label.task": "Task",
      "label.task_plural": "Tasks",
      "label.attachment": "Attachment",
      "label.attachment_plural": "Attachments",
      "label.form_plural":"Forms",
      "label.appointment_info": "Appointment info",
      "label.file_name": "File name",
      "label.open_link": "Open link",
      "label.description": "Description",
      "label.download_file": "Download File",
      "label.subject": "Subject",
      "label.message": "Message",
      "label.message_plural": "Messages",
      "label.first_name": "First name",
      "label.last_name": "Last name",
      "label.date_of_birth": "Date of Birth",
      "label.contact_number": "Contact number",
      "label.residential_address": "Residential address", 
      "label.email": "Email",
      "label.preferred_language": "Preferred language",
      "label.collateral": "Resources",
      "label.back_inbox": "Back to Inbox",
      "label.click_view_message": "Click to view message",
      "label.send_message": "Send Message",
      "label.send_reply": "Send Reply",
      "label.confirm_details": "Confirm details",
      "label.confirm_appointment": "Confirm appointment",
      "label.add_to_calendar": "Add to calendar",
      "label.get_started": "Get Started!",
      "label.scan_qr_code": "Scan QR Code",
      "label.qr_code": "QR Code",
      "label.task": "Tasks",
      "label.heartrate": "Heartrate",
      "label.click_to_complete": "Click to Complete",
      "placeholder.first_name": "Enter first name",
      "placeholder.last_name": "Enter last name",
      "placeholder.date_of_birth": "DD/MM/YYYY",
      "placeholder.contact_number": "Enter contact number",
      "placeholder.residential_address": "Residential address",
      "placeholder.email": "Enter email",
      "placeholder.preferred_language": "Select a language",
      "placeholder.enter_subject": "Enter subject",
      "placeholder.enter_message": "Enter message",
      "placeholder.enter_mobile_no": "Enter mobile number",
      "message.first_name_required": "First name is required",
      "message.last_name_required": "Last name is required",
      "message.date_of_birth_required": "Date of Birth is required",
      "message.contact_no_required": "Contact number is required",
      "message.mobile_no_required": "Mobile number is required",
      "message.residential_address_required": "Residential address is required",
      "message.email_required": "Email is required",
      "message.preferred_language_required": "Preferred Language is required",
      "message.no_attachment": "No attachment",
      "message.no_collateral": "No resources available",
      "message.confirmation_appointment": "This will confirm your appointment",
      "message.decline_appointment": "Are you sure you want to decline this appointment?",
      "message.delete_attachment": "This will delete this attachment",
      "message.update_attachment_error": "Error occurred while updating attachment, please try again.",
      "message.add_attachment_error": "Error occurred while adding attachment, please try again.",
      "message.delete_attachment_error": "Error occurred while deleting attachment, please try again.",
      "message.message_sent_success": "Message successfully sent.",
      "message.message_sent_error": "Error occured while sending message, please try again.",
      "message.reply_sent_success": "Reply successfully sent.",
      "message.reply_sent_error": "Error occured while sending reply, please try again.",
      "message.signing_out": "Signing out...",
      "message.invalid_otp": "Invalid OTP. Please try again.",
      "message.otp_resend_error": "Failed to send OTP. Please try again later." 
    }
  },
  fr: {
    translation: {
      "label.upcoming_appointments": 'Prochains rendez-vous',
      "label.past_appointments": "Rendez-vous passés",
      "label.inbox": "Boîte de réception",
      "label.logout": "Se déconnecter",
      "label.account": "Account",
      "label.view_details": "Voir les détails",
      "label.confirm": "Confirmer",
      "label.start_telehealth": "Démarrez la télésanté",
      "label.date_time": "Date / heure",
      "label.clinic": "Clinique",
      "label.phone_number": "Numéro de téléphone",
      "label.edit": "Éditer",
      "label.add": "Ajouter",
      "label.delete": "Effacer",
      "label.cancel": "Annuler",
      "label.ok": "D'accord",
      "label.back": "Retour",
      "label.save": "Sauvegarder",
      "label.reply": "Répondre",
      "label.next": "à côté",
      "label.skip": "Sauter",
      "label.attachment": "Attachement",
      "label.attachment_plural": "Attachements",
      "label.appointment_info": "Informations de rendez-vous",
      "label.file_name": "Nom de fichier",
      "label.open_link": "Ouvrir le lien",
      "label.description": "La description",
      "label.download_file": "Télécharger un fichier",
      "label.subject": "Matière",
      "label.message": "Message",
      "label.message_plural": "Messages",
      "label.first_name": "Prénom",
      "label.last_name": "Nom de famille",
      "label.date_of_birth": "Date de naissance",
      "label.contact_number": "Numéro de contact",
      "label.email": "Email",
      "label.preferred_language": "Langue Préférée",
      "label.collateral": "Ressources",
      "label.back_inbox": "Retour à la boîte de réception",
      "label.click_view_message": "Cliquez pour voir le message",
      "label.send_reply": "Envoyer la réponse",
      "label.confirm_details": "Confirmer les détails",
      "label.confirm_appointment": "Confirmer le rendez-vous",
      "label.add_to_calendar": "Ajouter au calendrier",
      "label.get_started": "Commencer!",
      "label.scan_qr_code": "scanner le code QR",
      "label.qr_code": "QR Code",
      "placeholder.first_name": "Entrez votre prénom",
      "placeholder.last_name": "Entrer le nom de famille",
      "placeholder.date_of_birth": "Entrer date de naissance",
      "placeholder.contact_number": "Entrez le numéro de contact",
      "placeholder.email": "Entrez l'email",
      "placeholder.preferred_language": "Sélectionnez une langue",
      "placeholder.enter_subject": "Entrez le sujet",
      "placeholder.enter_message": "Entrez le message",
      "message.first_name_required": "Le prénom est requis",
      "message.last_name_required": "Le nom est obligatoire",
      "message.date_of_birth_required": "La date de naissance est requise",
      "message.contact_no_required": "Le numéro de contact est requis",
      "message.email_required": "Email est requis",
      "message.preferred_language_required": "La langue préférée est requise",
      "message.no_attachment": "Pas d'attachement",
      "message.no_collateral": "Aucune garantie disponible",
      "message.confirmation_appointment": "Cela confirmera votre rendez-vous",
      "message.delete_attachment": "Cela supprimera cette pièce jointe",
      "message.update_attachment_error": "Une erreur s'est produite lors de la mise à jour de la pièce jointe, veuillez réessayer.",
      "message.add_attachment_error": "Une erreur s'est produite lors de l'ajout de la pièce jointe, veuillez réessayer.",
      "message.delete_attachment_error": "Une erreur s'est produite lors de la suppression de la pièce jointe, veuillez réessayer.",
      "message.reply_sent_success": "Réponse envoyée avec succès.",
      "message.reply_sent_error": "Une erreur s'est produite lors de l'envoi de la réponse, veuillez réessayer."
    }
  },
  ko: {
    translation: {
      "label.upcoming_appointments": 'Upcoming Appointments',
      "label.past_appointments": "Past Appointments",
      "label.inbox": "Inbox",
      "label.logout": "Logout",
      "label.account": "Account",
      "label.view_details": "View Details",
      "label.confirm": "Confirm",
      "label.start_telehealth": "Start Telehealth",
      "label.date_time": "Date / Time",
      "label.clinic": "Clinic",
      "label.phone_number": "Clinic Phone number",
      "label.edit": "Edit",
      "label.add": "Add",
      "label.delete": "Delete",
      "label.cancel": "Cancel",
      "label.ok": "Ok",
      "label.back": "Back",
      "label.save": "Save",
      "label.reply": "Reply",
      "label.next": "Next",
      "label.skip": "Skip",
      "label.attachment": "Attachment",
      "label.attachment_plural": "Attachments",
      "label.appointment_info": "Appointment info",
      "label.file_name": "File name",
      "label.open_link": "Open link",
      "label.description": "Description",
      "label.download_file": "Download File",
      "label.subject": "Subject",
      "label.message": "Message",
      "label.message_plural": "Messages",
      "label.first_name": "First name",
      "label.last_name": "Last name",
      "label.date_of_birth": "Date of Birth",
      "label.contact_number": "Contact number",
      "label.email": "Email",
      "label.preferred_language": "Preferred language",
      "label.collateral": "Resources",
      "label.back_inbox": "Back to Inbox",
      "label.click_view_message": "Click to view message",
      "label.send_reply": "Send Reply",
      "label.confirm_details": "Confirm details",
      "label.confirm_appointment": "Confirm appointment",
      "label.add_to_calendar": "Add to calendar",
      "label.scan_qr_code": "Scan QR Code",
      "label.qr_code": "QR Code",
      "placeholder.first_name": "Enter first name",
      "placeholder.last_name": "Enter last name",
      "placeholder.date_of_birth": "DD/MM/YYYY",
      "placeholder.contact_number": "Enter contact number",
      "placeholder.email": "Enter email",
      "placeholder.preferred_language": "Select a language",
      "placeholder.enter_subject": "Enter subject",
      "placeholder.enter_message": "Enter message",
      "message.first_name_required": "First name is required",
      "message.last_name_required": "Last name is required",
      "message.date_of_birth_required": "Date of Birth is required",
      "message.contact_no_required": "Contact number is required",
      "message.email_required": "Email is required",
      "message.preferred_language_required": "Preferred Language is required",
      "message.no_attachment": "No attachment",
      "message.no_collateral": "No resources available",
      "message.delete_attachment": "This will delete this attachment",
      "message.update_attachment_error": "Error occurred while updating attachment, please try again.",
      "message.add_attachment_error": "Error occurred while adding attachment, please try again.",
      "message.delete_attachment_error": "Error occurred while deleting attachment, please try again.",
      "message.reply_sent_success": "Reply successfully sent.",
      "message.reply_sent_error": "Error occured while sending reply, please try again."
    }
  },
  ja: {
    translation: {
      "label.upcoming_appointments": 'Upcoming Appointments',
      "label.past_appointments": "Past Appointments",
      "label.inbox": "Inbox",
      "label.logout": "Logout",
      "label.account": "Account",
      "label.view_details": "View Details",
      "label.confirm": "Confirm",
      "label.start_telehealth": "Start Telehealth",
      "label.date_time": "Date / Time",
      "label.clinic": "Clinic",
      "label.phone_number": "Clinic Phone number",
      "label.edit": "Edit",
      "label.add": "Add",
      "label.delete": "Delete",
      "label.cancel": "Cancel",
      "label.ok": "Ok",
      "label.back": "Back",
      "label.save": "Save",
      "label.reply": "Reply",
      "label.next": "Next",
      "label.skip": "Skip",
      "label.attachment": "Attachment",
      "label.attachment_plural": "Attachments",
      "label.appointment_info": "Appointment info",
      "label.file_name": "File name",
      "label.open_link": "Open link",
      "label.description": "Description",
      "label.download_file": "Download File",
      "label.subject": "Subject",
      "label.message": "Message",
      "label.message_plural": "Messages",
      "label.first_name": "First name",
      "label.last_name": "Last name",
      "label.date_of_birth": "Date of Birth",
      "label.contact_number": "Contact number",
      "label.email": "Email",
      "label.preferred_language": "Preferred language",
      "label.collateral": "Resources",
      "label.back_inbox": "Back to Inbox",
      "label.click_view_message": "Click to view message",
      "label.send_reply": "Send Reply",
      "label.confirm_details": "Confirm details",
      "label.confirm_appointment": "Confirm appointment",
      "label.add_to_calendar": "Add to calendar",
      "label.scan_qr_code": "Scan QR Code",
      "label.qr_code": "QR Code",
      "placeholder.first_name": "Enter first name",
      "placeholder.last_name": "Enter last name",
      "placeholder.date_of_birth": "Enter date of birth",
      "placeholder.contact_number": "Enter contact number",
      "placeholder.email": "Enter email",
      "placeholder.preferred_language": "Select a language",
      "placeholder.enter_subject": "Enter subject",
      "placeholder.enter_message": "Enter message",
      "message.first_name_required": "First name is required",
      "message.last_name_required": "Last name is required",
      "message.date_of_birth_required": "Date of Birth is required",
      "message.contact_no_required": "Contact number is required",
      "message.email_required": "Email is required",
      "message.preferred_language_required": "Preferred Language is required",
      "message.no_attachment": "No attachment",
      "message.no_collateral": "No resources available",
      "message.delete_attachment": "This will delete this attachment",
      "message.update_attachment_error": "Error occurred while updating attachment, please try again.",
      "message.add_attachment_error": "Error occurred while adding attachment, please try again.",
      "message.delete_attachment_error": "Error occurred while deleting attachment, please try again.",
      "message.reply_sent_success": "Reply successfully sent.",
      "message.reply_sent_error": "Error occured while sending reply, please try again."
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    fallbackLng: ["en"],

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
