import React, { useState } from 'react';
import { Button, Modal, Input, message as antdMessage } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addAppointmentMessage } from 'api/messages';
import { fetchAppointmentRequest } from 'redux/actions/appointment';

const SendMessageModal = props => {
    const { visible, hide, appointmentId, onSuccess} = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const [message, setMessage] = useState({subject: '', message: ''});

    const sendMessage = () => {
        addAppointmentMessage(appointmentId, message)
            .then(res => {
                antdMessage.success(t('message.message_sent_success'));
                dispatch(fetchAppointmentRequest(appointmentId));
                onSuccess();
                onCancel();
            })
            .catch(err => {
                antdMessage.error(t('message.message_sent_error'));
            })
    }

    const onCancel = () => {
        setMessage({subject: '', message: ''});
        hide();
    }

    return (
        <Modal
            title={(
                <span>
                    <strong>{t('label.send_message')}:</strong>
                </span>
            )}
            visible={visible}
            onCancel={onCancel}
            destroyOnClose={true}
            footer={[
                <Button
                    key='message'
                    onClick={sendMessage} 
                    disabled={!(message.subject !== '' && message.message !== '')}
                >
                    { t('label.send_message') }
                </Button>
            ]}
            centered
            zIndex={999}
        >
            <label>{t('label.subject')}:</label><br/>
            <Input
                placeholder={t('placeholder.enter_subject')} 
                value={message.subject} 
                onChange={e => setMessage({...message, subject: e.target.value})} 
            />
            <label>{t('label.message')}:</label><br/>
            <TextArea 
                rows={4} 
                placeholder={t('placeholder.enter_message')} 
                value={message.message} 
                onChange={e => setMessage({...message, message: e.target.value})} 
            />
        </Modal>
    )
}

export default SendMessageModal;