import React, { useEffect, useState } from "react";
import AppointmentSection from "components/Appointments/AppointmentSection";
import AppointmentInfo from "components/Appointments/AppointmentInfo";
import AttachmentList from "components/Appointments/AttachmentList";
import CollateralList from "components/Collateral/CollateralList";
import MessageList from "components/Appointments/MessageList";
import TaskList from "components/Appointments/TaskList";
import { Container, Row, Col } from "react-bootstrap";
import { Button } from "antd";
import { QrcodeOutlined } from "@ant-design/icons";
import { BsFillCameraVideoFill } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import { PUBLIC_BASE_URL } from "api/config";
import { fetchAppointmentRequest } from "redux/actions/appointment";
import { setPageTitle, setBreadcrumbsText } from "redux/actions/ui";
import { startTelehealthMeeting } from "redux/actions/zoom";

import { useTranslation } from "react-i18next";
import FormList from "components/Appointments/FormList";
import QRCodeModal from "components/Appointments/Modals/QRCodeModal";
import { useAppSelector } from "./../../../hooks/use-app-selector";
import { APPOINTMENTS_PERMISSIONS } from "redux/types/permissions-types/appointments-permissions";

function View() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, data: appointment, error } = useSelector(
    (state) => state.appointment
  );
  const { isTeleHealthButtonLoading } = useSelector((state) => state.zoom);
  const { messages } = appointment;

  const [qrCodeModalVisibility, setQrCodeModalVisibility] = useState(false);
  const [qrCodeImg, setQrCodeImg] = useState(null);

  const [taskId, setTaskId] = useState("");
  const [refreshTask, setRefreshTask] = useState(0);

  useEffect(() => {
    dispatch(fetchAppointmentRequest(id));
    dispatch(setPageTitle(appointment.appointmentType));
    dispatch(setBreadcrumbsText(appointment.appointmentType));

    if (modulePermissions.includes("appointment:telehealth")) {
        dispatch(setPageTitle(appointment.appointmentType))
    } else {
        dispatch(setPageTitle(" "))
    }

    return () => {
      dispatch(setPageTitle(""));
      dispatch(setBreadcrumbsText(""));
    };
  }, [id, dispatch, appointment.appointmentType]);

  const onStartAppointmentClick = () => {
    dispatch(startTelehealthMeeting(appointment.id));
  };

  const showQRCode = () => {
    const qrCode = `${PUBLIC_BASE_URL}/qrcode/${appointment.uniqueId}`;
    setQrCodeImg(qrCode);
    setQrCodeModalVisibility(true);
  };

  const modulePermissions = useAppSelector(
    (state) => state.permissions.permissions.Appointments
  );

  return (
    <>
      <QRCodeModal
        title={`${appointment.appointmentType} ${t("label.qr_code")}`}
        visible={qrCodeImg && qrCodeModalVisibility}
        onClose={() => {
          setQrCodeModalVisibility(false);
          setQrCodeImg(null);
        }}
        qrCode={qrCodeImg}
      />
      <Container fluid>
        <Row>
          {/* <Permission permissions={modulePermissions} permissionKey={APPOINTMENTS_PERMISSIONS['appointment:telehealth']}> */}
          {modulePermissions.includes("appointment:telehealth") ? (
            <>
              {appointment.attendanceType === "TELEHEALTH" && (
                <Col xs={12} md={6} style={{ marginBottom: "20px" }}>
                  <Button
                    className="Floating-Call-Button mb-4"
                    type="primary"
                    onClick={() => onStartAppointmentClick()}
                    loading={isTeleHealthButtonLoading}
                  >
                    <BsFillCameraVideoFill
                      style={{ marginTop: "-5px" }}
                      size={"2em"}
                    />
                    &nbsp;&nbsp;{t("label.start_telehealth")}
                  </Button>
                </Col>
              )}
            </>
          ) : <Col xs={12} md={6}>
                <h1 className='fivef-pageTitle mt-3 ml-0' style={{ fontSize: '3.5rem' }}>
                    {appointment.appointmentType}
                </h1>
            </Col>}
          {/* </Permission> */}
          <Col
            className="Contact-Cancellation"
            xs={12}
            md={appointment.attendanceType === "TELEHEALTH" && modulePermissions.includes("appointment:telehealth") ? 6 : 12}
          >
            <Button
              type="default"
              className="mb-4"
              onClick={showQRCode}
              icon={
                <QrcodeOutlined style={{ position: "relative", top: "-4px" }} />
              }
            >
              {t("label.scan_qr_code")}
            </Button>
            <h3 className="Appointment-Detail-Title">
              For Cancellations contact:{" "}
              <a href="tel:+61414719045">
                <strong>+61414719045</strong>
              </a>
            </h3>
          </Col>
        </Row>
        <AppointmentSection title={t("label.appointment_info")}>
          <AppointmentInfo appointment={appointment} />
        </AppointmentSection>
        <Row>
          <Col xs={12} md={6} lg={6} flex={1}>
            <AppointmentSection title={t("label.task_plural")}>
              <TaskList
                setTaskId={setTaskId}
                refreshTask={refreshTask}
                setRefreshTask={setRefreshTask}
                appointmentId={appointment.id}
              />
            </AppointmentSection>
          </Col>
          <Col xs={12} md={6} lg={6} flex={1}>
            <AppointmentSection title={t("label.message_plural")}>
              <MessageList messages={messages} appointmentId={appointment.id} />
            </AppointmentSection>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6} flex={1}>
            <AppointmentSection title={t("label.form_plural")}>
              <FormList
                appointment={appointment}
                attachments={appointment.attachments}
              />
            </AppointmentSection>
          </Col>
          <Col xs={12} md={6} lg={6}>
            <AppointmentSection title={t("label.attachment_plural")}>
              <AttachmentList
                appointment={appointment}
                attachments={appointment.attachments}
                taskId={taskId}
                setTaskId={setTaskId}
                setRefreshTask={setRefreshTask}
              />
            </AppointmentSection>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6} lg={6}>
            <AppointmentSection title={t("label.collateral")}>
              <CollateralList
                collaterals={appointment.collaterals}
                appointmentId={appointment.id}
              />
            </AppointmentSection>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default View;
