export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_ERROR = 'CREATE_DOCUMENT_ERROR';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR';

export const DELETE_DOCUMENT_REQUEST = 'DELETE_DOCUMENT_REQUEST';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR';

export const SET_DOCUMENTS_MODAL_VISIBILITY = 'SET_DOCUMENTS_MODAL_VISIBILITY';
export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_DELETE_MODAL_VISIBILITY = 'SET_DELETE_MODAL_VISIBILITY';