import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

const StyledCol = styled(Col)`
    height: calc(100% - 17px);
    &.form > .container-fluid {
        height: inherit;
    }
`;

function AppointmentSection(props) {
    const { title, children } = props;

    return (
        <>
            <Row style={{height: '100%'}}>
                <Col xs={12} md={12} lg={12} style={{height: '18px'}} className='mb-3'>
                    <h4>
                        <strong className='text-uppercase text-bolder' style={{ color: '#343434' }}>{title}</strong>
                    </h4>
                </Col>
                <StyledCol className={title === 'Forms' && 'form'} xs={12} md={12} lg={12}>
                    {children}
                </StyledCol>
            </Row><br/>
        </>
    );
}

export default AppointmentSection;