import {AUTH_ACTIONS, AuthAction, AuthState} from "../types/auth-types";

const initialState: AuthState = {
    token: undefined
};

export default function authReducer(
    state = initialState,
    action: AuthAction
): AuthState {
    const { type, payload } = action;
    let token;
    if (payload) {
        token = payload.token
    }
    // const {token} = payload;
    switch (type) {
        case AUTH_ACTIONS.SET_TOKEN:
            return {
                // ...state,
                token,
            };
        default:
            return { ...state };
    }
}
