import React, { useEffect, useState } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';

import AppointmentCard from 'components/Appointments/AppointmentCard';

import {
    previousStep,
    setOnBoardingModalVisibility
} from 'redux/actions/onBoarding';

import {
    confirmAppointmentStatus
} from 'redux/actions/appointment';

import {
    setTourVisibility,
} from 'redux/actions/ui';

import { useTranslation } from 'react-i18next';

const ConfirmAppointment = props => {

    
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const appointmentState = useSelector(state => state.appointment);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col>
                        <div className='text-center'>
                            {appointmentState.list.length > 0 
                                && <AppointmentCard ActionLayout={null} 
                                item={appointmentState.list[0]} 
                                span={'UPCOMING'} />}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{
                        order: 1,
                        span: 12
                    }} md={{
                        order: 1,
                        offset: 3,
                        span: 6
                    }}>
                        <Button type='primary' className='mb-3' style={{ width: '100%' }} onClick={() => {
                            const firstAppointment = appointmentState.list[0];
                            dispatch(setOnBoardingModalVisibility(false));
                            dispatch(confirmAppointmentStatus(firstAppointment.id));
                            dispatch(setTourVisibility(true));
                        }}>
                            {t('label.confirm_appointment')}
                        </Button>
                    </Col>
                    <Col xs={{
                        order: 4,
                        span: 12
                    }} md={{
                        order: 4,
                        offset: 3,
                        span: 6
                    }} className='mb-3'>
                        <div>
                            <Button 
                                style={{ width: '100%' }} 
                                onClick={() => dispatch(previousStep())}
                            >
                                {t('label.back')}
                            </Button>
                        </div>
                    </Col>
                    <Col xs={{
                        order: 2,
                        span: 12
                    }} md={{
                        order: 2,
                        offset: 3,
                        span: 6
                    }} className='mb-3'>
                        <div>
                            <Button style={{ width: '100%' }} 
                            // onClick={() => {
                            //     dispatch(setOnBoardingModalVisibility(false));
                            //     dispatch(setTourVisibility(true));
                            // }}
                            >
                                {t('label.add_to_calendar')}
                            </Button>
                        </div>
                    </Col>
                    <Col xs={{
                        order: 3,
                        span: 12
                    }} md={{
                        order: 3,
                        offset: 3,
                        span: 6
                    }} className='mb-3'>
                        <div>
                            <Button style={{ width: '100%' }} onClick={() => {
                                dispatch(setOnBoardingModalVisibility(false));
                                dispatch(setTourVisibility(true));
                            }}>
                                {t('label.skip')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ConfirmAppointment;
