import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const formsSubmissionApi = createAxios({
    baseURL: API_BASE_URL + '/form-submissions'
});

export const fetchFormById = id => {
    return formsSubmissionApi.get(`/${id}`);
}

export const fetchAppointmentFormsDataTable = (appointmentId, params) => {
    return formsSubmissionApi.get(``, {
        params: {
            appointmentId: appointmentId,
            ...params
        }
    });
}

export const fetchFormsSummary = params => {
    return formsSubmissionApi.get(``, {
        params
    })
}

export const updateFormSubmissions = (id, payload) => {
    return formsSubmissionApi.put(
        `/${id}`,
        payload,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        });
};

export const getFormSubmission = id => {
    return formsSubmissionApi.get(`/${id}`);
};

export const getIncompleteFormSubmissions = () => {
    return formsSubmissionApi.get(`/incomplete/count`);
}

export const getMostRecentAnswerPrefill = (formDesignId) => {
    return formsSubmissionApi.get(`/prefill/most-recent?designId=${formDesignId}`);
}