import React from 'react';
import moment from "moment";
import {
    Apartment,
    Assignment,
    AssignmentInd,
    RadioButtonChecked,
    Schedule,
    Today,
    CheckCircle,
} from '@material-ui/icons';
import {
    RiCloseCircleLine
} from 'react-icons/all';
import { Badge, Card } from "antd";
import { Container, Row, Col } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

const AppointmentCard = ({ ActionLayout, item, span }) => {
    const { t } = useTranslation();

    const returnStatusTemplate = status => {
        if (status === 'UNCONFIRMED') {
            return <RadioButtonChecked className='Appointment-Icon' fontSize="small" />
        }

        if (status === 'CONFIRMED') {
            return <CheckCircle fontSize="small" style={{ color: "#4caf50" }} />
        }

        if (status === 'DECLINED') {
            return <RiCloseCircleLine className='Appointment-Icon' fontSize='small' style={{ fontSize: '2.2rem', color: '#f44336' }} />
        }
    };

    console.log(item);

    return (
        <Card className="Appointment-Card" actions={ActionLayout}>
            <div className={`Appointment-Date-Container ${item.attendanceType === 'REFERRAL' ? 'Purple-Background' : 'Primary-Background'}`}>
                {item.attendanceType !== 'REFERRAL' ? <><p>{moment(item.appointmentDateTime).format("MMMM").toString()}</p>
                <h1>{moment(item.appointmentDateTime).format("DD").toString()}</h1>
                <p>{moment(item.appointmentDateTime).format("YYYY").toString()}</p></> : <p>REFERRAL</p>}
            </div>
            <Container fluid>
                {/* <Row>
                    <Col xs={8}>
                        <h4 className="Appointment-Booking-Details">
                            <Today className='Appointment-Icon' fontSize="small" />{" "}
                            {moment(item.appointmentDateTime).format("DD MMMM YYYY").toString()}
                        </h4>
                    </Col>
                    <Col xs={4} className='text-right'>
                        <h4 className="Appointment-Booking-Details">
                            <Schedule className='Appointment-Icon' fontSize="small" />{" "}
                            {moment(item.appointmentDateTime).format("hh:mm A").toString()}
                        </h4>
                    </Col>
                </Row><hr/> */}
                <Row>
                    <Col
                        xs={{
                            offset: 4,
                            span: 8
                        }}
                        md={{
                            offset: 4,
                            span: 8
                        }}
                        lg={{
                            offset: 3,
                            span: 9
                        }}
                    >
                        <h6 className='text-muted font-weight-bold text-uppercase Appointment-Type-Title'>{(`${span} APPOINTMENT` === 'UPCOMING APPOINTMENT' ? t('label.upcoming_appointments') : t('label.past_appointments'))}</h6>
                        <h4 className="Appointment-Booking-Title">{item.appointmentType}</h4>
                        <Badge
                            className={item.attendanceType === 'REFERRAL' ? 'Appointment-Referral-Badge' : 'Appointment-Badge'}
                            count={item.attendanceType}
                            size={"small"}
                        />
                        {span !== 'PAST' && <h4 className="Appointment-Booking-Details">
                            {returnStatusTemplate(item.status)}&nbsp;
                            {item.status}
                        </h4>}
                        {item.locationName && <h4 className="Appointment-Booking-Details Location">
                            <Apartment className='Appointment-Icon' fontSize="small" />{" "}
                            {item.locationName}
                        </h4>}
                    </Col>
                </Row>
            </Container>
        </Card>
    )
};

export default AppointmentCard;
