import React, { useState, useEffect } from 'react';
import { getPatientTasks, updatePatientTaskStatus } from 'api/patientTasks';
import { Button, Tag, Table, message } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import {CompleteButton} from "../CompleteButton";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setWillTaskListFetch } from 'redux/actions/appointment';

const TaskList = ({setTaskId, refreshTask, setRefreshTask, appointmentId}) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const [patientTasks, setPatientTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const appointmentState = useSelector(state => state.appointment);
   
  useEffect(()=>{
    if((!patientTasks?.length || refreshTask) && appointmentId) {
      fetchPatientTasks(currentPage);
      setRefreshTask(0);
    }   
  },[refreshTask, appointmentId]);

  const renderPatientTasksAction = (text, record, value) => {
    let action = null;
    if(record.status === 'NEW') {
      action = 
      <CompleteButton
        type="primary" 
        onClick={ () => {
            if(record.taskType === 'CUSTOM' || record.taskType === 'FORM') {
              updatePatientTaskStatus(record?.id, 'COMPLETED')
              .then(res => {
                  message.success('Successfully completed task.');
                  fetchPatientTasks(currentPage);
              })
            } else if(record.taskType === 'ATTACHMENT') {
              setTaskId(record?.id);
            }
        }}
      >
        { t('label.click_to_complete') }
      </CompleteButton>
    } else {
      action = <Tag color='green' icon={<CheckCircleFilled style={{position: 'relative', top: '-2px', height: '10px'}} />}>DONE</Tag>
    }
    return action;
  }

  const fetchPatientTasks = async (page) => {
    const params = { 
      length: 5, 
      page: page, 
      sortColumn: 'createdAt', 
      sortDirection: 'DESC',
      appointmentId: appointmentId
    }
    const res = await getPatientTasks(params);
    setPatientTasks(res.data);
    setTimeout(()=>{
      setIsLoading(false);
    },500);
  }

  const columns = [
    {
      title: 'Task',
      dataIndex: "description",
      key: "description",
      sorter: false
    },
    {
      title: 'Actions',
      dataIndex: "id",
      key: "id",
      sorter: false,
      render: renderPatientTasksAction
    },
  ];

  useEffect(() => {
      if (appointmentState.willTaskListFetch) {
          fetchPatientTasks(currentPage);

          return () => {
            dispatch(setWillTaskListFetch(false));
          }
      }
  }, [appointmentState.willTaskListFetch]);

  return ( 
    <Table 
        columns={columns}
        loading={isLoading}
        dataSource={patientTasks.data}
        pagination={{
          total: patientTasks?.recordsTotal,
          pageSize: 5,
          onChange: (page, pageSize) => {
            setCurrentPage(page - 1);
            fetchPatientTasks(page - 1);
          }
        }}
    />
  );
};

export default TaskList;
