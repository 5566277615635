import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { hexToRgbA } from 'helpers/utils';

export default ({ branding }) => {
    const GlobalStyle:any = createGlobalStyle`
        html,
        body,
        .MuiTypography-h6 {
            font-family: ${branding.theme.typography.fontFamily}, sans-serif!important;
        }

        .anticon {
            vertical-align: unset!important;
        }

        #page-wrapper {
            background: ${branding.theme.palette.background.default}!important;
        }

        .fivef-navbar {
            background: ${branding.theme.palette.navbar.background}!important;
            border-radius: 0!important;
            
            .fivef-navbar-brand,
            #responsive-navbar-nav,
            .nav-link,
            .navbar-light .navbar-nav .nav-link {
                color: ${branding.theme.palette.navbar.text}!important;
            }

            .btn-toggle-mb-sidebar {
                background: ${branding.theme.palette.navbar.background}!important;
                color: ${branding.theme.palette.navbar.text}!important;
            }
        }

        .ant-select {
            &:not(.ant-select-disabled):hover .ant-select-selector,
            &:not(.ant-select-disabled):focus .ant-select-selector,
            &:not(.ant-select-disabled):active .ant-select-selector {
                border-color: ${branding.theme.palette.primary.main}!important;
            }
        }

        .ant-pagination-item-active {
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-input {
            &:hover {
                border-color: ${branding.theme.palette.primary.main}!important;
            }

            &:focus {
                border-color: ${branding.theme.palette.primary.main}!important;
                outline: 0;
                -webkit-box-shadow: 0 0 0 2px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
                box-shadow: 0 0 0 2px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
            }
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: ${branding.theme.palette.primary.main}!important;
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-radio-checked .ant-radio-inner {
            border-color: ${branding.theme.palette.primary.main}!important;
        }
        
        .ant-radio-inner::after {
            background-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-btn {
            border: none!important;
        }

        .ant-btn-default {
            color: ${branding.theme.palette.button.primaryBackground}!important;
            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
        }

        .ant-btn:not(.ant-btn-primary) {
        
            &:hover > span,
            &:focus > span, 
            &:active > span {
                color: ${branding.theme.palette.button.primaryBackground}!important;
            }
        }

        .ant-btn-primary {
            background-color: ${branding.theme.palette.button.primaryBackground}!important;
            color: ${branding.theme.palette.button.text}!important;
        }

        .Logo-Label,
        .fivef-pageTitle {
            color: ${branding.theme.palette.header.primary}!important;
        }

        .fivef-user-dropdown {
            a {
                color: ${branding.theme.palette.primary.main}!important;
                font-weight: 700
            }
        }

        .sidebar {
            margin-top: 10px!important;
            padding-top: 15px!important;
            ul {
                li {
                    a {
                        color: ${branding.theme.palette.sidebar.mainText}!important;
                        font-weight: 600!important;

                        &:hover {
                            background-color: ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                        }
                        
                        &.active {
                            background-color: ${branding.theme.palette.sidebar.activeBackground}!important;
                            color: ${branding.theme.palette.sidebar.activeText}!important;
                            -webkit-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                            -moz-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.sidebar.activeBackground, 0.2)}!important;
                        }
                    }
                }
            }
        }

        .Topbar-Toolbar-Items {
            color: ${branding.theme.palette.button.primaryBackground}!important;
            .Toolbar-Options {
                color: ${branding.theme.palette.button.primaryBackground}!important;
            }
        }

        .Form-Builder-Topbar .Form-Builder-Top-Title {
            color: ${branding.theme.palette.header.primary}!important;
        }

        .Form-Builder-Topbar {
            -webkit-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            -moz-box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .Form-Builder-Toolbox,
        .Form-Builder-Settings {
            border-top: 5px solid ${branding.theme.palette.primary.main}!important;
        }

        .ant-switch-checked {
            background-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-radio-group-solid {
            .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                background: ${branding.theme.palette.primary.main}!important;
                border-color: ${branding.theme.palette.primary.main}!important;
            }
        }

        .ant-card-bordered {
            border-color: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-tabs-tab {
            &:hover {
                color: ${branding.theme.palette.primary.light}!important;
            }

            &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                    color: ${branding.theme.palette.primary.main}!important;
                }
            }
        }

        .ant-tabs-ink-bar {
            background: ${branding.theme.palette.primary.main}!important;
        }

        a,
        .ant-btn-link {
            color: ${branding.theme.palette.primary.main}!important;
        }

        .text-primary {
            color: ${branding.theme.palette.primary.main}!important;
        }

        .choose-image {
            background-color: ${branding.theme.palette.button.primaryBackground}!important;
            color: ${branding.theme.palette.button.text}!important;
            font-size: 12px;
        }

        .Branding-Logo-Settings-Header {
            color: ${branding.theme.palette.header.primary}!important;
        }

        .ant-picker:hover,
        .ant-picker-focused {
            border-color: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
            background: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
            background: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
            background: ${hexToRgbA(branding.theme.palette.primary.main, 0.2)}!important;
        }

        .ant-picker-range .ant-picker-active-bar {
            background: ${branding.theme.palette.primary.main}!important;
        }

        .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
            border-color: ${hexToRgbA(branding.theme.palette.primary.main, 0.6)}!important;
        }

        .ant-avatar {
            background-color: ${branding.theme.palette.primary.light}!important;
        }

        .Appointment-Icon {
            color: ${branding.theme.palette.primary.main};
        }

        .Appointment-Booking-Title,
        .Appointment-Type-Title {
            color: ${branding.theme.palette.header.primary}!important;
        }

        .Appointment-Badge {
            sup {
                background-color: ${branding.theme.palette.primary.light}!important;
            }
        }

        .Appointment-Referral-Badge {
          sup {
            background-color: #8e44ad !important;
          }
        }

        .Appointment-Card {
            -webkit-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            -moz-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
        }

        .ant-spin-dot-item {
            background-color: ${branding.theme.palette.primary.main}!important;
        }

        .Card-Gradient-Primary {
            background-color: ${hexToRgbA(branding.theme.palette.primary.main, 0.8)}!important;
            box-shadow: 0px 10px 10px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};
        }

        .Floating-Call-Button {
            background: #35C235!important;
        }
        
        .Primary-Background {
          background: ${branding.theme.palette.button.primaryBackground}!important;
          
          sup {
            background-color: ${branding.theme.palette.primary.light}!important;
          }
        }
        
        .Purple-Background {
          background: #8e44ad !important;

        }

        .Appointment-Date-Container {
            position: absolute;
            top: -5px;
            left: 15px;
            min-width: 20%;
            padding: 20px 10px 10px;
            color: #fff;
            text-align: center;
            border-radius: 15px;
            -webkit-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            -moz-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;

            h1 {
                font-weight: 800;
                font-size: 2.5rem;
                color: #fff;
                margin-top: 10px;
                margin-bottom: 15px;
            }

            & p {
                color: ${branding.theme.palette.button.text};
                font-size: 1.3rem;
                font-weight: 700
            }
            
        }

        .BottomNavigationTab {
            color: ${branding.theme.palette.primary.main}!important;
        }

        .File-Uploader-Button {
            border-color: ${branding.theme.palette.button.primaryBackground}!important;
            -webkit-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            -moz-box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            box-shadow: 0px 4px 5px 0px ${hexToRgbA(branding.theme.palette.primary.main, 0.2)};!important;
            color: ${branding.theme.palette.button.primaryBackground}!important;
        }

        /* Extra small devices (phones, 600px and down) */
        @media only screen and (max-width: 600px) {
            .Appointment-Date-Container {
                min-width: 26%;
            }
        }

        /* Small devices (portrait tablets and large phones, 600px and up) */
        @media only screen and (min-width: 600px) {}

        /* Medium devices (landscape tablets, 768px and up) */
        @media only screen and (min-width: 768px) and (orientation: landscape) {
            .Appointment-Date-Container {
                left: 8px!important;
            }
        }


        /* Large devices (laptops/desktops, 992px and up) */
        @media only screen and (min-width: 992px) {
            .Appointment-Date-Container {
                left: 8px!important;
            }
        }

        /* Extra large devices (large laptops and desktops, 1200px and up) */
        @media only screen and (min-width: 1200px) {
            .Appointment-Date-Container {
                left: 15px!important;
            }
        }
        
        /* ----------- iPad Pro ----------- */
        /* Portrait and Landscape */
        @media only screen 
        and (min-width: 1024px) 
        and (max-height: 1366px) 
        and (-webkit-min-device-pixel-ratio: 1.5) {
            .Appointment-Date-Container {
                left: 8px!important;
            }
        }
    `;

    return <GlobalStyle theme={branding.theme} />
}