import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {keycloak, initOptions} from './api/keycloak';
import { getBrandingByTenant, getMultipleTenantBranding } from 'api/branding';
import { setTenantBranding } from './redux/actions/tenants';

import 'App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'sb-admin-2.scss'
import 'style.scss'

import MainLayout from 'components/Layout/MainLayout';
import Alert from "react-bootstrap/cjs/Alert";
import 'antd/dist/antd.css';
import Signup from "./pages/Signup";
import {Redirect, Switch, useHistory} from "react-router";
import {AuthContext, useAuthContext} from "./hooks";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import routes from "./routes";

import Landing from 'modules/LoginModule';
import OTPComponent from 'modules/LoginModule/components/OTPComponent';
import {getToken} from './api/token';
import {useAppSelector} from './hooks/use-app-selector';
import {useDispatch} from 'react-redux';
import {AUTH_ACTIONS} from './redux/types/auth-types';
import Cookies from 'js-cookie';
import { TOKEN_NAME } from './constants/index';
import { createGoogleFontLinkTag } from 'helpers/utils';
import GlobalStyle from 'theme';
import CollectionNotice from './components/CollectionNotice';

function App() {
    const signupUri = '/signup';
    const landingUri = '/';

    const [sessionState] = useState({
        sessionDetails: [
            {fullName: 'Hisham Alabri'}],
        otherState: 'not sure what this is'
    });

    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const defaultUri = routes(isAuthenticated)[0].path;
    const auth = useAppSelector(state => state.auth);
    const tenant = useAppSelector(state => state.tenant);
    const dispatch = useDispatch();
    const history = useHistory();

    const { branding } = tenant;

    const buildGoogleFontApiSource = (typography) => {
        if (typography?.fontUrl) {
            createGoogleFontLinkTag(typography.fontUrl);
        }
    }

    const setPageTitle = () => {
        if (branding.page.appTitle) {
            document.title = 'Patient Portal - ' + branding.page.appTitle
        }
    }

    useEffect(() => {
        setPageTitle();
        const requestBrandingBasedOnSettings = async () => {
            try {
                const response = await getMultipleTenantBranding(`${tenant.uniqueId.replace('.patient', '')}, ${tenant.uniqueId}`);

                if (response.data.data.theme) {
                    const adminBranding = response.data.data.theme.find(responseDataTheme => JSON.parse(responseDataTheme).tenantUniqueId === tenant.uniqueId.replace('.patient', ''));
                    const patientBranding = response.data.data.theme.find(responseDataTheme => JSON.parse(responseDataTheme).tenantUniqueId === tenant.uniqueId);
                    const adminJsonBranding = JSON.parse(adminBranding);

                    if (adminJsonBranding.centraliseBranding === true) {
                        buildGoogleFontApiSource(adminJsonBranding.theme.typography);
                        dispatch(setTenantBranding(adminJsonBranding));
                    } else {
                        const patientJsonBranding = JSON.parse(patientBranding);
                        buildGoogleFontApiSource(patientJsonBranding.theme.typography);
                        dispatch(setTenantBranding(patientJsonBranding));
                    }
                }
            } catch (error) {
                console.log(error);

                if (branding) {
                    dispatch(setTenantBranding(branding));
                }
            }
        }

        requestBrandingBasedOnSettings();
    }, []);

    useEffect(() => {
        setIsAuthenticated(!!auth.token)

        /* @TODO fix keycloak
                keycloak.init({
                    onLoad: initOptions.onLoad,
                    checkLoginIframe: false
                }).then((auth) => {
                    console.log('in keycloak init ', auth);
                    setIsAuthenticated(auth);
                }).catch((e) => {
                    console.error("Authentication Failed");
                    console.error(e);
                });
        */
    }, [auth]);

    useEffect(() => {
        // @todo check expiry of token

        dispatch({
            type: AUTH_ACTIONS.SET_TOKEN,
            payload: {token: Cookies.get(TOKEN_NAME)}
        })
    }, [Cookies.get(TOKEN_NAME)])

    return (
        <AuthContext.Provider value={isAuthenticated}>
            <GlobalStyle branding={branding} />
            <BrowserRouter basename=''>
                <Switch>
                    <Route path={`/collection-notice`}>
                      <CollectionNotice />
                    </Route>
                    <Route path="/console/meeting/:meetingNumber/:meetingPassword/:appointmentId"
                           component={(props) => {

                               console.log('starting a meeting with', props.match.params.meetingNumber);
                               window.location.href = 'http://localhost:3001/meeting/'
                                   + props.match.params.meetingNumber + "/"
                                   + props.match.params.meetingPassword + "/"
                                   + props.match.params.appointmentId;
                               return null;
                           }}/>
                    <AuthenticatedRoute
                        path={signupUri}
                        onAuthenticated={
                            () => <Redirect to={defaultUri}/>
                        }
                        onUnauthenticated={
                            () => <Signup signupUri={signupUri}/>
                        }
                    />

                    <AuthenticatedRoute
                        exact path={landingUri}
                        onAuthenticated={
                            () => {
                                console.log(`on Authenticated route landing: redirect to ${defaultUri}`);
                                return <Redirect to={defaultUri}/>;
                            }
                        }
                        onUnauthenticated={
                            () => {
                                console.log('on Unauthenticated route Landing: render Landing');
                                return <Landing keycloak={keycloak}/>;
                            }
                        }
                    />

                    <AuthenticatedRoute
                        onAuthenticated={
                            () => {
                                console.log('on Authenticated route main: render Main');
                                return <MainLayout fullName={sessionState.sessionDetails[0].fullName}/>;
                            }
                        }
                        onUnauthenticated={
                            () => {
                                console.log('on Unauthenticated route main: redirect to Landing')
                                return <Redirect to={landingUri}/>;
                            }
                        }
                    />

                </Switch>
                <Alert></Alert>
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;