import React from 'react';
import './MainLayout.scss';
import Navigation from "./Navigation";
import PageWrapper from "./PageWrapper";
import BottomNavigationMenu from './BottomNavigationMenu';
import OnBoardingModal from 'pages/OnBoarding/Modal';
import Aux from '../../hoc/AuxFunction';
import { useSelector } from 'react-redux';
import { useAppSelector } from './../../hooks/use-app-selector';

const MainLayout = (props) => {
   
    const { children } = props;
    const currentUser = useSelector(state => state.currentUser.data);
    const permissions = useAppSelector(state => state.permissions.permissions);

    return (
        <Aux>
            <Navigation fullName={props.fullName}/>
            <PageWrapper page={children} />
            {/* { !localStorage.getItem('onboarded')  && */}
                {currentUser?.onboarded === false && permissions.Onboarding ? <OnBoardingModal /> : null}
            {/* } */}
            <BottomNavigationMenu />
        </Aux>
    );
};

export default MainLayout;
