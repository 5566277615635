import { TenantAction, TenantState, TENANT_ACTIONS } from "redux/types/tenant-types";

export const setTenantBranding = (branding:any):TenantAction => {
    let returnAction = {
        type: TENANT_ACTIONS.SET_TENANT_BRANDING,
        payload: {
            branding
        }
    }
    
    return returnAction
}

export const setTenant = (payload:TenantState):TenantAction => {
    return {
        type: TENANT_ACTIONS.SET_TENANT,
        payload
    }
}