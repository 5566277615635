import {API_BASE_URL} from 'api/config';
import {createAxios} from './axios';

const otpApi = createAxios({
    baseURL: API_BASE_URL + '/otp'
});

export const createOTP = (payload) => {
    return otpApi.post('/create', payload);
};

export const validateOTP = (payload) => {
    return otpApi.post('/validate', payload);
}