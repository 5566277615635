import { 
    TOGGLE_SIDEBAR_VISIBILITY, 
    SET_STATUS_MODAL_VISIBILITY,
    SET_MESSAGE_MODAL_VISIBILITY,
    SET_COLLATERAL_MODAL_VISIBILITY,
    SET_NOTE_MODAL_VISIBILITY,
    SET_TOUR_VISIBILITY,
    SET_NOTIFICATIONS_VISIBILITY,
    SET_PAGE_TITLE,
    SET_BREADCRUMBS_TEXT,
} from 'redux/actions/types/ui';

export const toggleSideBarVisibility = () => {
    return {
        type: TOGGLE_SIDEBAR_VISIBILITY
    }
};

export const setStatusModalVisibility = isStatusModalVisible => {
    return {
        type: SET_STATUS_MODAL_VISIBILITY,
        isStatusModalVisible,
    }
};

export const setMessageModalVisibility = isMessageModalVisible => {
    return {
        type: SET_MESSAGE_MODAL_VISIBILITY,
        isMessageModalVisible,
    }
};

export const setCollateralModalVisibility = isCollateralModalVisible => {
    return {
        type: SET_COLLATERAL_MODAL_VISIBILITY,
        isCollateralModalVisible,
    }
};

export const setNoteModalVisibility = isNoteModalVisible => {
    return {
        type: SET_NOTE_MODAL_VISIBILITY,
        isNoteModalVisible,
    }
};

export const setTourVisibility = isTourVisible => {
    return {
        type: SET_TOUR_VISIBILITY,
        isTourVisible,
    }
};

export const setNotificationsVisibility = isNotificationsVisible => {
    return {
        type: SET_NOTIFICATIONS_VISIBILITY,
        isNotificationsVisible,
    }
};

export const setPageTitle = pageTitle => ({
    type: SET_PAGE_TITLE,
    pageTitle
});

export const setBreadcrumbsText = currentBreadcrumbDisplay => {
    return {
        type: SET_BREADCRUMBS_TEXT,
        currentBreadcrumbDisplay
    }
};