import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

const messagesApi = createAxios({
    baseURL: API_BASE_URL + '/messages'
});

export const getMessagesByPatientId = (id, additionalParams = '') => {
    return messagesApi.get(`?patientId=${id}${additionalParams}`);
};

export const getMessageById = id => {
    return messagesApi.get(`/${id}`);
};

export const markMessageAsRead = (id) => {
    const userId = 0;
    return messagesApi.post(`read/${userId}`,
        JSON.stringify([id]),
        {
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
};

export const addAppointmentMessage = (appointmentId, payload) => {
    return messagesApi.post(``, {
        appointmentId: appointmentId,
        ...payload
    })
};

export const getAppointmentMessagesDataTable = params => {  
    return messagesApi.get(``, {
        params
    });
}