import React, {
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "hooks/use-app-selector";
import {
  fetchAppointmentsBySpan,
  confirmAppointmentStatus,
  declineAppointmentStatus,
} from "redux/actions/appointment";
import { startTelehealthMeeting } from "redux/actions/zoom";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import { List, Spin } from "antd";
import AppointmentCard from "components/Appointments/AppointmentCard";
import ConfirmationModal from "components/Appointments/Modals/ConfirmationModal";
import DeclineModal from "components/Appointments/Modals/DeclineModal";
import {
  PAST,
  UPCOMING_APPOINTMENTS,
  PAST_APPOINTMENTS,
} from "constants/appointment-constants";
import { AssignmentTurnedIn, ListAlt } from "@material-ui/icons";
import {BsFillCameraVideoFill, AiFillCloseCircle, MdAddToQueue} from "react-icons/all";
import { AppointmentStatuses } from "enums/appointment-statuses";
import { AppointmentAttendanceTypes } from "enums/appointment-attendance-types";
import { APPOINTMENTS_PERMISSIONS } from "redux/types/permissions-types/appointments-permissions";
import {useHistory} from "react-router";

interface AppointmentsModuleProps {
  span: string;
}

const AppointmentsModule: FunctionComponent<AppointmentsModuleProps> = ({
  span,
}) => {
  const [activeAppointmentId, setActiveAppointmentId] = useState(0);
  const appointments = useAppSelector((state) => state.appointment);
  const permissions = useAppSelector((state) => state.permissions.permissions);
  const pageSize = 6;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const fetchAppointments = useCallback(
    (page: number) => {
      const sortDirection = span === PAST ? "DESC" : "ASC";
      dispatch(
        fetchAppointmentsBySpan(span, {
          length: pageSize,
          page: page - 1,
          sortDirection: sortDirection,
          sortColumn: "appointmentDateTime",
        })
      );
    },
    [span, dispatch]
  );

  useEffect(() => {
    fetchAppointments(1);
  }, [dispatch, span, fetchAppointments]);

  /** VIEWING APPOINTMENT */
  const ViewDetails = (id: any) => {
    const spanLink = span === PAST ? PAST_APPOINTMENTS : UPCOMING_APPOINTMENTS;
    return (
      <Link to={`/${spanLink}/${id}`}>
        <ListAlt fontSize="large" />{" "}
        <span className="d-block d-lg-block font-weight-bold">
          {t("label.view_details")}
        </span>
      </Link>
    );
  };

  /** CONFIRMING APPOINTMENT */
  const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
  const ConfirmAppointment = (id: React.SetStateAction<number>) => (
    <div
      onClick={() => {
        setActiveAppointmentId(id);
        setConfirmModalVisibility(true);
      }}
    >
      <AssignmentTurnedIn fontSize="large" />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.confirm")}
      </span>
    </div>
  );
  const onConfirmationClick = () => {
    setConfirmModalVisibility(false);
    dispatch(confirmAppointmentStatus(activeAppointmentId));
  };

  /** DECLINING APPOINTMENT */
  const [isDeclineModalVisible, setDeclineModalVisibility] = useState(false);
  const DeclineAppointment = (id: React.SetStateAction<number>) => (
    <div
      className="decline-button"
      onClick={() => {
        setActiveAppointmentId(id);
        setDeclineModalVisibility(true);
      }}
    >
      <AiFillCloseCircle style={{ fontSize: "3.5rem" }} />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.decline")}
      </span>
    </div>
  );
  const onDeclineClick = () => {
    setDeclineModalVisibility(false);
    dispatch(declineAppointmentStatus(activeAppointmentId));
  };

  /** TELEHEALTH */
  const StartMeeting = (appointmentId: any) => (
    <div onClick={() => onStartMeetingClick(appointmentId)}>
      <BsFillCameraVideoFill size={"2.5em"} />{" "}
      <span className="d-block d-lg-block font-weight-bold">
        {t("label.start_telehealth")}
      </span>
    </div>
  );

    /** REFERRAL */
    const Referral = (appointmentId: any) => (
        <div onClick={() => history.push('/referral-booking')}>
            <MdAddToQueue size={"2.5em"} color={`#8e44ad`} />{" "}
            <span className="d-block d-lg-block font-weight-bold" style={{
                color: '#8e44ad'
            }}>
        {'Book Appointment'}
      </span>
        </div>
    );

  function onStartMeetingClick(appointmentId: any) {
    dispatch(startTelehealthMeeting(appointmentId));
  }

  return (
    <>
      <ConfirmationModal
        visible={isConfirmModalVisible}
        onOk={onConfirmationClick}
        onCancel={() => setConfirmModalVisibility(false)}
      />
      <DeclineModal
        visible={isDeclineModalVisible}
        onOk={() => onDeclineClick()}
        onCancel={() => setDeclineModalVisibility(false)}
      />
      <Spin spinning={appointments.isListPageLoading}>
        <List
          className={"Appointments-List"}
          grid={{
            gutter: 20,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 3,
            xxl: 3,
          }}
          pagination={{
            total: appointments?.recordsTotal,
            pageSize: pageSize,
            onChange: (page) => fetchAppointments(page),
          }}
          dataSource={appointments.list}
          renderItem={(item, i) => {
            const { id } = item;
            const isConfirmed = item.status === AppointmentStatuses.Confirmed;
            const isDeclined = item.status === AppointmentStatuses.Declined;

            let ActionLayout = [ViewDetails(id)];

            if (permissions.Appointments?.includes("appointment:confirm")) {
              if (!isConfirmed && span !== PAST) {
                ActionLayout = [ViewDetails(id), ConfirmAppointment(id)];
              }
            }

            if (isConfirmed && span !== PAST) {
              ActionLayout = [ViewDetails(id)];
            }

            if (permissions.Appointments?.includes("appointment:confirm")) {
              if (
                item.attendanceType === AppointmentAttendanceTypes.Telehealth &&
                span !== PAST &&
                !isConfirmed
              ) {
                ActionLayout = [ViewDetails(id), ConfirmAppointment(id)];
              }
            }
            if (permissions.Appointments?.includes("appointment:telehealth")) {
              if (
                item.attendanceType === AppointmentAttendanceTypes.Telehealth &&
                span !== PAST &&
                isConfirmed
              ) {
                ActionLayout = [ViewDetails(id), StartMeeting(id)];
              }
            }

            if (item.attendanceType === "REFERRAL") {
                ActionLayout = [Referral(id)]
            }

            if (permissions.Appointments?.includes("appointment:decline")) {
              if (span !== PAST && !isConfirmed) {
                ActionLayout = [...ActionLayout, DeclineAppointment(id)];
              }
            }

            if (isDeclined) {
              ActionLayout = [ViewDetails(id)];
            }

            return (
              <List.Item>
                <AppointmentCard
                  ActionLayout={ActionLayout}
                  item={item}
                  span={span}
                />
              </List.Item>
            );
          }}
        />
      </Spin>
    </>
  );
};

export default AppointmentsModule;
