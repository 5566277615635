import { API_BASE_URL } from './config';
import { createAxios } from './axios';

const brandingApi = createAxios({
    baseURL: `${API_BASE_URL}/branding`
});

export const getMultipleTenantBranding = (tenantUniqueIds:string) => {
    return brandingApi.get(`?tenantUniqueIds=${tenantUniqueIds}`);
}

export const getBrandingByTenant = (tenantUniqueId:string) => {
    return brandingApi.get(`/${tenantUniqueId}`);
};

export const getBrandingLogoByTenant = (tenantUniqueId:any) => {
    return `${API_BASE_URL}/branding/${tenantUniqueId}/logo`;
};