import {FieldComponent} from 'interfaces';

export enum AUTHENTICATION_TYPES {
    MOBILE = "MOBILE",
    FIVE_FACES_SSO = "FIVE_FACES_SSO",
}

export enum TENANT_ACTIONS {
    SET_TENANT = "SET_TENANT",
    SET_TENANT_BRANDING = 'SET_TENANT_BRANDING'
}

export enum REGISTRATION_TYPE {
    INVITE = 'registration-invite',
    STAGING = 'registration-staging'
}

export interface TenantRegistration {
    type: REGISTRATION_TYPE,
    fields: FieldComponent[]
}

export interface TenantAction {
    type: keyof typeof TENANT_ACTIONS,
    payload: Partial<TenantState>
}

export interface TenantState {
    id: number | undefined;
    uniqueId: string | undefined;
    name: string | undefined;
    branding: {
        logo: string | undefined;
        isPoweredByVisible: boolean | undefined,
        tenantUniqueId: string | null | undefined;
        page: string | undefined | Object;
        theme: string | undefined | Object;
    }
    authenticationType: AUTHENTICATION_TYPES[] | undefined,
    registration: TenantRegistration | undefined
}