import React, { useEffect } from 'react';
import { Button, Select, DatePicker } from 'antd';
import { Row, Container, Col } from 'react-bootstrap';
import ConfirmDetailsImageSrc from 'assets/svgs/onboarding-1.svg';

import { InputField, SelectField, DatePickerField, CleaveInputField } from 'components/Controls';

import { 
    setOnBoardingCurrentActiveStep,
} from 'redux/actions/onBoarding';

import {
    setCurrentLanguage
} from 'redux/actions/i18n';

import { useTranslation } from 'react-i18next';

import { connect, useDispatch } from 'react-redux';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { onboardUser } from 'api/users';

const { Option } = Select;

const ConfirmDetails = props => {
    const { setOnBoardingCurrentActiveStep, currentActiveStep } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    async function onboard() {
        await onboardUser();
    }
    
    useEffect(() => {
        onboard();
    }, [])

    return (
        <>
            <div className='text-center'>
                <img className='Vector-Art-Image' src={ConfirmDetailsImageSrc} alt='Confirm Details' />
            </div>
            <Formik
                initialValues={{
                    firstName: '',
                    lastName: '',
                    dateOfBirth: moment().format("DD/MM/YYY"),
                    contactNumber: '',
                    // email: '',
                    preferredLanguage: 'en',
                }}
                validationSchema={Yup.object({
                    firstName: Yup.string().required(t('message.first_name_required')),
                    lastName: Yup.string().required(t('message.last_name_required')),
                    // dateOfBirth: Yup.string().required(t('message.date_of_birth_required')),
                    // contactNumber: Yup.string().required(t('message.contact_no_required')),
                    residentialAddress: Yup.string().required(t('message.residential_address_required')),
                    // email: Yup.string().required(t('message.email_required')),
                    preferredLanguage: Yup.string().required(t('message.preferred_language_required'))
                })}
                onSubmit={values => {
                    const currentStep = currentActiveStep + 1;
                    i18n.changeLanguage(values.preferredLanguage);
                    dispatch(setCurrentLanguage(values.preferredLanguage));
                    setOnBoardingCurrentActiveStep(currentStep);
                }}
            >
                {props => (
                    <Form>
                        <Container fluid>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Field 
                                        id='firstName'
                                        name='firstName'
                                        type='text'
                                        label={t('label.first_name')}
                                        placeholder={t('placeholder.first_name')}
                                        size='large'
                                        component={InputField}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <Field 
                                        id='lastName'
                                        name='lastName'
                                        type='text'
                                        label={t('label.last_name')}
                                        placeholder={t('placeholder.last_name')}
                                        size='large'
                                        component={InputField}
                                    />
                                </Col>
                                {/* <Col xs={12} md={6}>
                                    <Field 
                                        id='dateOfBirth'
                                        name='dateOfBirth'
                                        label={t('label.date_of_birth')}
                                        placeholder={t('placeholder.date_of_birth')}
                                        component={CleaveInputField}
                                        size='large'
                                        options={
                                            {
                                                date: true,
                                                datePattern: ['d', 'm', 'Y']
                                            }
                                        }
                                    />
                                </Col> */}
                                {/* <Col xs={12} md={6}>
                                    <Field 
                                        id='contactNumber'
                                        name='contactNumber'
                                        type='text'
                                        label={t('label.contact_number')}
                                        placeholder={t('placeholder.contact_number')}
                                        size='large'
                                        component={InputField}
                                    />
                                </Col> */}
                                <Col xs={12} md={6}>
                                    <Field 
                                        id='residentialAddress'
                                        name='residentialAddress'
                                        type='text'
                                        label={t('label.residential_address')}
                                        placeholder={t('placeholder.residential_address')}
                                        size='large'
                                        component={InputField}
                                    />
                                </Col>
                                {/*<Col xs={12} md={6}>*/}
                                {/*    <Field */}
                                {/*        id='email'*/}
                                {/*        name='email'*/}
                                {/*        type='email'*/}
                                {/*        label={t('label.email')}*/}
                                {/*        placeholder={t('placeholder.email')}*/}
                                {/*        size='large'*/}
                                {/*        component={InputField}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                <Col xs={12} md={6}>
                                    <Field
                                        id='preferredLanguage'
                                        name='preferredLanguage'
                                        label={t('label.preferred_language')}
                                        placeholder={t('placeholder.preferred_language_required')}
                                        onChange={value => {
                                            console.log(value)
                                            i18n.changeLanguage(value);
                                            dispatch(setCurrentLanguage(value));
                                        }}
                                        size='large'
                                        style={{
                                            width: '100%',
                                            // zIndex: '9999'
                                        }}
                                        dropdownStyle={{
                                            zIndex: 99999999
                                        }}
                                        component={SelectField}
                                    >
                                        <Option value="en">English</Option>
                                        <Option value="fr">French</Option>
                                        <Option value="ko">Korean</Option>
                                        <Option value="ja">Japanese</Option>
                                    </Field>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={12} md={12}>
                                    <Button 
                                        className='first-step' 
                                        type='primary' 
                                        htmlType='submit' 
                                        style={{ width: '100%' }}
                                    >
                                        {t('label.next')}
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                )}
            </Formik>
                
        </>
    );
};

export default connect(
    state => {
        return {
            currentActiveStep: state.onBoarding.currentActiveStep
        }
    },
    dispatch => {
        return {
            setOnBoardingCurrentActiveStep: step => dispatch(setOnBoardingCurrentActiveStep(step))
        }
    }
)(ConfirmDetails);
