import React, { useState, useRef } from 'react';
import { PaperClipOutlined } from '@ant-design/icons';

import { Container, Row, Col } from 'react-bootstrap';

const FileUploader = ({ 
    field, 
    form: { touched, errors, setFieldValue, getFieldValue }, 
    onChange, 
    ...props 
}) => {
    const [fileName, setFileName] = useState('');
    const inputFile = useRef(null);

    const handleFileUploadClick = () => {
        inputFile.current.click();
    };

    const handleFileUploadChange = event => {
        const fileUploaded = event.target.files[0];
        setFileName(fileUploaded.name);

        setFieldValue(field.name, fileUploaded);
    };
 
    return (
        <>
            <input type='file' 
                    style={{ display: 'none' }} 
                    onChange={handleFileUploadChange}
                    ref={inputFile} />
            <button 
                className='File-Uploader-Button' 
                onClick={handleFileUploadClick}
            >
                <PaperClipOutlined style={{ fontSize: '2rem' }} />
                Attach a file
            </button>
            <div className='File-Upload-List'>
                <Container fluid>
                    <Row>
                        <Col xs={12} md={12}>
                            {fileName}
                        </Col>
                        <Col className='text-right'>
                            {touched[field.name] && errors[field.name] && (
                                <p className='text-danger'>{errors[field.name]}</p>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
};

export default FileUploader;