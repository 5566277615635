import { API_BASE_URL } from 'api/config';
import {createAxios} from "./axios";

const appointmentApi = createAxios({
    baseURL: API_BASE_URL + '/appointment-collaterals'
});

export const getCollateralFile = (id) => {
    return appointmentApi.get(`/${id}/file`);
}

export const getCollateralFileUrl = (id) => {
    return appointmentApi.get(`/${id}/url`);
}

export const getAppointmentCollaterals = (id, params) => {
    return appointmentApi.get(``, {
        params: {
            appointmentId: id,
            ...params
        }
    });
}