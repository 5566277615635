import React, { useState, useEffect } from 'react';
import {Col, Container, Row} from'react-bootstrap';
import defaultLogo from 'assets/images/5f-dark-logo.png';

import 'styles/Signup.scss';
import 'styles/SignupTransition.scss';

import {CSSTransition} from "react-transition-group";
import TwoFactorAuth from "./Staging/TwoFactorAuth";
import Credentials from "./Staging/Credentials";
import {Route, Switch, useLocation} from "react-router";
import {BrowserRouter} from "react-router-dom";
import PatientDetails from 'pages/Signup/Staging/PatientDetails';
import InviteForm from 'pages/Signup/Invite';
import TokenError from 'pages/Signup/Invite/TokenError';
import { useSelector } from 'react-redux';
import { getBrandingLogoByTenant } from 'api/branding';

const Signup = ({signupUri}) => {
    const {search} = useLocation();
    const s = new URLSearchParams(search);
    const tenant = useSelector(state => state.tenant);
    const [currentLogo, setCurrentLogo] = useState(getBrandingLogoByTenant(tenant.branding.tenantUniqueId));

    useEffect(() => {
        setCurrentLogo(getBrandingLogoByTenant(tenant.branding.tenantUniqueId));
    }, [tenant.branding.tenantUniqueId]);

    return (
        <Container className='Signup-Container'>
            <Row>
                <Col className='text-center'>
                    <img className='logo' src={currentLogo} onError={() => {
                        setCurrentLogo(defaultLogo)
                    }}/>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <h4 className='Logo-Label w-100 mb-2 mt-4'>Digital Patient Journey</h4>
                    <h3 className='Logo-Label Signup-Text'>Sign up</h3>
                </Col>
            </Row>
            <BrowserRouter>
                <Switch>
                    <Route exact path={`${signupUri}/invite/:token`} component={InviteForm} />
                    <Route exact path={`${signupUri}`}>
                        {({match}) => (
                            <CSSTransition
                                in={match}
                                timeout={300}
                                unmountOnExit
                                classNames='signup-step'>
                                <PatientDetails signupUri={signupUri} />
                            </CSSTransition>
                        )}
                    </Route>
                    <Route exact path={`${signupUri}/:uniqueId`}>
                        {({match}) => (
                            <CSSTransition
                                in={match}
                                timeout={300}
                                unmountOnExit
                                classNames='signup-step'>
                                <TwoFactorAuth uniqueId={match.params.uniqueId} onSuccess={(value) =>
                                    window.location.href = `${signupUri}/${match.params.uniqueId}/complete?pinUniqueId=${value.data}`
                                }/>
                            </CSSTransition>
                        )}
                    </Route>
                    <Route path={`${signupUri}/:uniqueId/complete`}>
                        {({match}) => (
                            <CSSTransition
                                in={match}
                                timeout={300}
                                unmountOnExit
                                classNames='signup-step'>
                                <Credentials uniqueId={match.params.uniqueId} pinConfirmationUniqueId={s.get('pinUniqueId')} />
                            </CSSTransition>
                        )}
                    </Route>
                </Switch>
            </BrowserRouter>
            {/*<CSSTransition*/}
            {/*    in={step === 2}*/}
            {/*    timeout={300}*/}
            {/*    unmountOnExit*/}
            {/*    classNames='signup-step'>*/}
            {/*    <TwoFactorAuth uniqueId={uniqueId} onSuccess={value => {*/}
            {/*        setPinConfirmationUniqueId(value.data);*/}
            {/*        setStep(step + 1);*/}
            {/*    }}/>*/}
            {/*</CSSTransition>*/}
            {/*<CSSTransition*/}
            {/*    in={step === 3}*/}
            {/*    timeout={300}*/}
            {/*    unmountOnExit*/}
            {/*    classNames='signup-step'>*/}
            {/*    <Credentials uniqueId={uniqueId} pinConfirmationUniqueId={pinConfirmationUniqueId} />*/}
            {/*</CSSTransition>*/}
        </Container>

    );
}

export default Signup;