import React  from "react";
import { Container } from "react-bootstrap";

const Card = ({background, padding = 20, children}) => {
  return (
    <Container background={background} padding={padding} fluid>
      {children}
    </Container>
  )
};

Card.craft = {
  displayName: 'Card'
}

export default Card;
