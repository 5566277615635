import React from 'react';
import { 
    Input as AntInput, 
    Card,
    Slider,
} from 'antd';
import { 
    Container, 
    Row, 
    Col,
} from 'react-bootstrap';

import DeleteButton from 'pages/FormBuilder/common/DeleteButton';

import { useNode, useEditor } from '@craftjs/core';

const Panel = ({
    cardTitle = 'Panel Title',
    cardTitleColor = '#000',
    headingBackground = '#fff',
    bodyPadding = {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
    },
    margin = {
        top: 10,
        left: 10,
        right: 10,
        bottom: 10,
    },
    children,
}) => {
    const { connectors: { connect, drag } } = useNode((state) => ({
        selected: state.events.selected
    }));

    const { top, left, right, bottom } = bodyPadding;

    const { query } = useEditor();

    return (
        <div 
            className={query.getOptions().enabled && 'With-Mark'}
            ref={ref => {
                connect(drag(ref));
            }}
        >
            <Card
                title={cardTitle} 
                headStyle={{ 
                    backgroundColor: headingBackground,
                    textAlign: 'center',
                    color: cardTitleColor
                }}
                bodyStyle={{
                    padding: `${top}px ${right}px ${bottom}px ${left}px`,
                }}
                style={{
                    margin: `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`,
                }}
            >
                {children}
            </Card>
        </div>
    );
};

const PanelSettings = () => {
    const { 
            actions: { setProp }, 
            cardTitle, 
            cardTitleColor, 
            headingBackground, 
            bodyPadding,
            margin
        } = useNode((node) => ({
        cardTitle: node.data.props.cardTitle,
        cardTitleColor: node.data.props.cardTitleColor,
        headingBackground: node.data.props.headingBackground,
        bodyPadding: node.data.props.bodyPadding,
        margin: node.data.props.margin,
    }));

    return (
        <>
            <Row>
                <Col xs={12}>
                    <label>Heading Title:</label>
                    <AntInput 
                        defaultValue={cardTitle} 
                        onChange={e => {
                            setProp(props => props.cardTitle = e.target.value);
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <label>Heading Title Color:</label>
                    <AntInput 
                        defaultValue={cardTitleColor} 
                        onChange={e => {
                            setProp(props => props.cardTitleColor = e.target.value);
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <label>Heading Background:</label>
                    <AntInput
                        defaultValue={headingBackground}
                        onChange={e => {
                            setProp(props => props.headingBackground = e.target.value);
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <label>Margin:</label><br/>
                    <Container style={{ padding: '5px 0' }} fluid>
                        <Row>
                            <Col>
                                <label className='Sub-Label'>Top:</label>
                                <Slider 
                                    defaultValue={margin.top} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.margin.top = value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <label className='Sub-Label'>Right:</label>
                                <Slider 
                                    defaultValue={margin.right} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.margin.right = value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='Sub-Label'>Bottom:</label>
                                <Slider 
                                    defaultValue={margin.bottom} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.margin.bottom = value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <label className='Sub-Label'>Left:</label>
                                <Slider 
                                    defaultValue={margin.left} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.margin.left = value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    {/* <AntInput
                        defaultValue={margin.replace('px', '')}
                        onChange={e => {
                            setProp(props => props.margin = e.target.value);
                        }}
                        addonAfter={'px'}
                    /> */}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <label>Body Padding:</label><br/>
                    <Container style={{ padding: '5px 0' }} fluid>
                        <Row>
                            <Col>
                                <label className='Sub-Label'>Top:</label>
                                <Slider 
                                    defaultValue={bodyPadding.top} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.bodyPadding.top = value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <label className='Sub-Label'>Right:</label>
                                <Slider 
                                    defaultValue={bodyPadding.right} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.bodyPadding.right = value)
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='Sub-Label'>Bottom:</label>
                                <Slider 
                                    defaultValue={bodyPadding.bottom} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.bodyPadding.bottom = value)
                                    }}
                                />
                            </Col>
                            <Col>
                                <label className='Sub-Label'>Left:</label>
                                <Slider 
                                    defaultValue={bodyPadding.left} 
                                    min={10}
                                    max={500}
                                    step={10}
                                    onChange={value => {
                                        setProp(props => props.bodyPadding.left = value)
                                    }}
                                />
                            </Col>
                        </Row>
                    </Container>
                    {/* <AntInput
                        defaultValue={bodyPadding.replace('px', '')} 
                        addonAfter={'px'}
                        onChange={e => {
                            setProp(props => props.bodyPadding = e.target.value);
                        }}
                    /> */}
                </Col>
            </Row>
        </>
    )
};

Panel.craft = {
    displayName: 'Panel',
    props: {
        cardTitle: 'Panel Title',
        cardTitleColor: '#000',
        headingBackground: '#fff',
        bodyPadding: {
            top: 10,
            left: 10,
            right: 10,
            bottom: 10,
        },
        margin: {
            top: 10,
            left: 10,
            right: 10,
            bottom: 10,
        }
    },
    related: {
        settings: PanelSettings,
    }
};

export default Panel;