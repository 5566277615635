import {
    SET_ONBOARDING_CURRENT_ACTIVE_STEP,
    PREVIOUS_STEP,
    NEXT_STEP,
    SET_ONBOARDING_MODAL_VISIBILITY,
} from 'redux/actions/types/onBoarding';

export const setOnBoardingModalVisibility = isDialogVisible => {
    return {
        type: SET_ONBOARDING_MODAL_VISIBILITY,
        isDialogVisible
    }
};

export const setOnBoardingCurrentActiveStep = step => {
    return {
        type: SET_ONBOARDING_CURRENT_ACTIVE_STEP,
        step,
    }
};

export const previousStep = () => {
    return {
        type: PREVIOUS_STEP,
    }
};

export const nextStep = () => {
    return {
        type: NEXT_STEP,
    }
};