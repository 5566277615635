import React from 'react';

export function evaluate(eventProperties, componentVal, availableActions) {
    if(!eventProperties.conditionalEventEnabled) return;

    const conditionResult = evaluateConditions(eventProperties, componentVal);
    const doAction = evaluateAction(eventProperties.action, conditionResult, availableActions);

    eventProperties.selectedControls.map( control => {
        doAction(control.id, conditionResult);
    })
}

function evaluateConditions({ conditions }, componentVal) {
    if(!conditions || conditions === undefined) throw Error('No conditions provided');

    let result = false;
    let conResult = false;
    conditions.map(con => {
        const {condition, conditionalValue, logicalOperator} = con;
        let conditionalVal = conditionalValue;

        if (!componentVal) {
            componentVal = '';
        }

        if (!conditionalVal) {
            conditionalVal = '';
        }

        switch (condition.value) {
            case 'equal_to':
                conResult = conditionalVal.toString() === componentVal?.toString();
                break;
            case 'not_equal_to':
                conResult = conditionalVal.toString() !== componentVal?.toString();
                break;
            case 'includes':
                conResult = componentVal.toString().includes(conditionalVal?.toString());
                break;
            case 'not_includes':
                conResult = !componentVal.toString().includes(conditionalVal?.toString());
                break;
            default:
                throw Error(`Unknown event condition ${condition.value}`);
        }

        if(logicalOperator)
            switch(logicalOperator.value) {
                case 'or':
                    result |= conResult;
                    break;
                case 'and':
                    result &= conResult;
                    break;
                default:
                    throw Error(`Unknown event condition ${logicalOperator.value}`);
            }
        else
            result = conResult;
    });

    return result;
}

function evaluateAction(action, other, availableActions) {
    const { setHidden } = availableActions;
    

    switch(action.value) {
        case 'hide':
            return controlId => {
                setHidden(controlId, other);
            }
        case 'show':
            return controlId => {
                setHidden(controlId, !other);
            }
        default:
            throw Error(`Unknown event action ${action.value}`);
    }
}