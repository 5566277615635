import {
    TenantRegistration,
    REGISTRATION_TYPE
} from 'redux/types/tenant-types';

export const InviteRegistrationType: TenantRegistration = {
    type: REGISTRATION_TYPE.INVITE,
    fields: [
        {
            id: 'firstName',
            label: 'First name',
            name: 'firstName',
            placeholder: 'Enter your first name',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your first name'
            }]
        },
        {
            id: 'lastName',
            label: 'Last name',
            name: 'lastName',
            placeholder: 'Enter your last name',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your last name'
            }]
        },
        {
            id: 'mobileNo',
            label: 'Mobile number',
            name: 'mobileNo',
            placeholder: 'Enter your mobile number',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your mobile number'
            }]
        },
        {
            id: 'dob',
            label: 'Date of Birth',
            name: 'dob',
            placeholder: 'Enter your Date of Birth',
            size: 'large',
            type: 'date',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your date of birth'
            }]
        },
        // {
        //     id: 'sex',
        //     label: 'Sex',
        //     name: 'sex',
        //     size: 'large',
        //     type: 'dropdown',
        //     placeholder: 'Please select your gender',
        //     defaultValue: '',
        //     items: [
        //         {
        //             value: 'Male',
        //             displayText: 'Male'
        //         },
        //         {
        //             value: 'Female',
        //             displayText: 'Female'
        //         }
        //     ],
        //     validation: [{
        //         type: 'required',
        //         message: 'Please enter your gender'
        //     }]
        // },
        {
            id: 'medicareNo',
            label: 'Medicare number',
            name: 'medicareNo',
            size: 'large',
            type: 'text',
            placeholder: 'Enter your medicare number (Optional)',
            defaultValue: ''
        }
    ]
}

export const StagingRegistrationType: TenantRegistration = {
    type: REGISTRATION_TYPE.STAGING,
    fields: [
        {
            id: 'firstName',
            label: 'First name',
            name: 'firstName',
            placeholder: 'Enter your first name',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your first name'
            }]
        },
        {
            id: 'lastName',
            label: 'Last name',
            name: 'lastName',
            placeholder: 'Enter your last name',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your last name'
            }]
        },
        {
            id: 'mobileNo',
            label: 'Mobile number',
            name: 'mobileNo',
            placeholder: 'Enter your mobile number',
            size: 'large',
            type: 'text',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your mobile number'
            }]
        },
        {
            id: 'dob',
            label: 'Date of Birth',
            name: 'dob',
            placeholder: 'Enter your Date of Birth',
            size: 'large',
            type: 'date',
            defaultValue: '',
            validation: [{
                type: 'required',
                message: 'Please enter your date of birth'
            }]
        }
    ]
}