import { SET_CURRENT_LANGUAGE } from 'redux/actions/types/i18n';

const INITIAL_STATE = {
    currentLanguage: 'en',
};

const i18nReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;


    switch (type) {
        case SET_CURRENT_LANGUAGE: {
            return {
                ...state,
                currentLanguage: payload
            }
        }

        default:
            return state
    }
};

export default i18nReducer;
