import React, {useEffect, useState} from 'react';
import {useEditor} from "@craftjs/core";

const editorSelectors = [];
let defaultSelector = () => {};

const EditorSelector = () => {
    const { actions: { selectNode }, selected } = useEditor(state => {
        const currentNodeId = state.events.selected;
        let selected;

        if (currentNodeId) {
            const node = state.nodes[currentNodeId];
            const nodeRelated = node.related;
            const nodeData = node.data;

            selected = {
                id: currentNodeId,
                name: nodeData.displayName,
                settings: nodeRelated && nodeRelated.settings,
                type: (nodeData.displayName === 'Input' ? nodeData.props.type : ''),
                eventSettings: nodeRelated && nodeRelated.eventSettings
            };
        }

        return {
            selected
        }
    });

    // prevent sending null to selectors
    if(selected == null) return null;

    const enabledSelectors = editorSelectors.filter(sel => sel.enabled);

    if( enabledSelectors.length > 0 )
        enabledSelectors.map(selector => {
            selector.onSelect(selected);
        });
    else
        defaultSelector(selected);

    // reset editor selected control
    selectNode(null);

    return null;
}

export function useEditorDefaultSelector(onSelect = () => {}) {
    defaultSelector = onSelect;
}

export function useEditorSelector(onSelect = () => {}, name) {
    let selector;

    const setSelector = (s) => {
        const filteredSelector = editorSelectors.filter(sel => {
            return sel.name === s.name;
        });

        if(filteredSelector && filteredSelector.length > 0)
            filteredSelector[0].enabled = s.enabled;
    }

    const existingSelectors = editorSelectors.filter(sel => sel.name === name);
    if(existingSelectors.length < 1) {
        selector = {
            enabled:false,
            onSelect,
            name
        }

        editorSelectors.push(selector);
    } else {
        selector = existingSelectors[0];
    }

    return { actions: { setSelector }, selector };
}

export default EditorSelector;