import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from "formik";
import InputField from "components/Controls/InputField";
import {Alert, Container, Row, Col} from "react-bootstrap";
import {message, Button} from "antd";
import {keycloak} from 'api/keycloak';

import 'styles/Signup.scss'
import * as Yup from 'yup';

import {Link} from "react-router-dom";
import {addPatient} from "redux/actions/users";
import {useDispatch, useSelector} from "react-redux";
import {completeAddPatientUser} from "api/users";


const Signup = ({ signupUri, uniqueId, pinConfirmationUniqueId }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Formik
            initialValues={{
                username: '',
                password: '',
                confirmPassword: ''
            }}
            validationSchema={ Yup.object({
                username: Yup.string().required('Please provide a username'),
                password: Yup.string()
                    .test('', 'Password should be at least 4 characters', val => val && val.length >= 4),
                confirmPassword: Yup.string()
                    .oneOf([Yup.ref('password'), null], 'Password doesn\'t match')
                    .required('Please re-enter your Password')
            })}
            onSubmit={values => {
                const payload = {
                    username: values.username,
                    password: values.password,
                    pinConfirmationUniqueId: pinConfirmationUniqueId
                };

                setIsLoading(true);
                completeAddPatientUser(payload, uniqueId)
                .then(response => {
                    message.success('Successful');

                    setTimeout(() => {
                        keycloak.login({
                            redirectUri: window.location.href.replace(signupUri, '')
                        })
                    }, 2000)
                }).catch((err) => {
                    const response = err.response;
                    if(response && response.data && response.status === 409){
                        message.error("Username already exist");
                    } else {
                        console.log(response);
                        message.error('Something went wrong');
                    }
                }).finally(() => setIsLoading(false));
            }}
            >
            <Form>
                <Row>
                    <Col>
                        <Field
                            id='username'
                            name='username'
                            label='Username'
                            placeholder='Enter username'
                            size='large'
                            component={InputField} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            id='password'
                            name='password'
                            label='Password'
                            type='password'
                            placeholder='Enter password'
                            size='large'
                            component={InputField} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            id='confirmPassword'
                            name='confirmPassword'
                            label='Confirm Password'
                            type='password'
                            placeholder='Re-enter password'
                            size='large'
                            component={InputField} />
                    </Col>
                </Row>
                <div className='Content-Footer'>
                    <Row>
                        <Col>
                            <Button htmlType='submit' loading={isLoading} type="primary" block>Sign Up</Button>
                        </Col>
                    </Row>
                    <div className='Sub-Footer'>
                        <Row>
                            <Col>
                                Already have an account? <Link to='#' onClick={() => window.location.href = '/'}>Login here</Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </Formik>
    );
}

export default Signup;