import Keycloak from "keycloak-js";
import {KEYCLOAK_AUTH_URL} from "./config";

const initOptions = {
    url: KEYCLOAK_AUTH_URL,
    realm: 'Concierge',
    clientId: 'dpj-concierge',
    onLoad: 'check-sso'
}

const keycloak = Keycloak(initOptions);

keycloak.onTokenExpired = () => {
    console.log('updating token...');
    keycloak.updateToken(0)
        .then((success) => {
            if(success) {
                console.log('done refreshing token');
            } else {
                console.log('failed to refresh token');
            }
        })
        .catch(() => console.log('failed to refresh token'));
}

keycloak.onAuthSuccess = () => console.log('onAuthSuccess');
keycloak.onAuthLogout = (data) => console.log('onLogout', data)

export { keycloak, initOptions };