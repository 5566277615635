import React, {useEffect, useState} from "react";
import AntdDataTable from "components/DataTable/AntdDataTable";
import {Button, Card, Space, message} from "antd";
import titleize from "titleize";
import DataTableFilter from "./DataTableFilter";

const CRUDDataTable = (props) => {

  const [data, setData] = useState([]);
  const [columns, setColumns] = useState(props.columns);
  const [draw, setDraw] = useState(1);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState({
    order: props.sortOrder ? props.sortOrder : props.columns[0].dataIndex,
    direction: "ASC",
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: props.pageSize ?? 10,
  });
  const [total, setTotal] = useState(0);
  const customFilters = props.customFilters;

  const [filters, setFilters] = useState(columns.reduce((filter, col, i) => {
    if(col.filter) {
      filter[col.filter.paramName] = { label: col.filter.label };
    }

    return filter;
  }, {}));


  useEffect(() => {
    if (props.draw >= 1) {
      fetchData(filters, sort, pagination)
    }
  }, [props.draw])

  useEffect(() => {
    if (customFilters !== undefined) {
      fetchData(filters, sort, pagination);
    }
  }, [customFilters])

  useEffect(() => {
    fetchData(filters, sort, pagination);
  }, [filters, sort, pagination])

  const fetchData = async (filters, sort, pagination) => {
    try {
      let params = {
        page: pagination.current - 1,
        draw: draw,
        start: (pagination.current - 1) * pagination.pageSize,
        length: pagination.pageSize,
        sortColumn: sort.order,
        sortDirection: sort.direction
      };

      if (customFilters !== undefined) {
        customFilters.forEach((filter, index) => {
          params[filter.paramName] = filter.value
        })
      }

      if (filters)
        Object.keys(filters).forEach(p => {
          if(filters[p].beforeRequest) {
            params[p] = filters[p].beforeRequest(filters[p].value)
          } else {
            params[p] = filters[p].value
          }
        })

      const res = await props.fetchDataFunction(params);
      setData(res.data.data);
      setTotal(res.data.recordsTotal);
      setColumns(columns);
      setDraw(res.data.draw);
    } catch (error) {
      console.log(error); // the api returns it under fled name 'message'
      message.error(error.message);
    }
  }

  useEffect(() => {
    const newFilters = {...filters}
    if(newFilters.search) {
      newFilters.search.value = search;
    } else {
      newFilters.search = { value: search }
    }

    setFilters(newFilters);
  }, [search])

  function handleChange(pagination, filters, sorter, extra) {
    setPagination(pagination);
    setSort({
      order: sorter.field ? sorter.field : columns[0].dataIndex,
      direction: sorter.order === "descend" ? "DESC" : "ASC",
    });
  }

  const [isCreateModalVisible, setCreateModalVisibility] = useState(false);

  let button = props.button !== undefined
    ? props.button
    : <Button type="primary" onClick={() => setCreateModalVisibility(true)}>
      New {props.entity === 'collateral' ? 'Resource' : titleize(props.entity)}
      {props.createModal}
    </Button>;

  const extra = <>
    <Space>
      {props.actions}
      {button}
    </Space>
    <DataTableFilter
        filterState={{ filters, setFilters }}
        filtersConfig={
          columns.filter(col => col.filter)
              .map(col => col.filter)}
        style={{ float: 'right' }} />
  </>

  return (
    <>
      {
        props.createModal &&
        React.cloneElement(props.createModal, {
          visible: isCreateModalVisible,
          setVisibility: setCreateModalVisibility,
          onSuccess: () => fetchData(search, sort, pagination)
        })
      }
      <Card
        className="mb-4"
        title={props.showTitle === false ? '' : (<span
          className="text-primary font-weight-bold">{props.entity === 'collateral' ? 'Resources' : titleize(props.entity)}</span>)}
        // extra={extra}
      >
        <AntdDataTable
          data={data}
          setData={setData}
          columns={columns}
          setColumns={setColumns}
          filtersState={{ filters, setFilters }}
          draw={draw}
          setDraw={setDraw}
          search={search}
          setSearch={setSearch}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          setPagination={setPagination}
          total={total}
          setTotal={setTotal}
          fetchData={fetchData}
          handleChange={handleChange}
          responsive={true}
          rowKey={props.rowKey}
          highlightRow={props.highlightRow}
        />
      </Card>
    </>
  );
};

export default CRUDDataTable;
