import { SET_CURRENT_USER, UNSET_CURRENT_USER } from 'redux/actions/types/currentUser';

const INITIAL_STATE = {
    data: undefined
};

const currentUserReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;
    switch (type) {

    case SET_CURRENT_USER: {
        return {
            data: payload
        }
    }

    case UNSET_CURRENT_USER: {
        return INITIAL_STATE
    }

    default:
        return state
    }
};

export default currentUserReducer;
