import {
    CREATE_DOCUMENT_ERROR,
    CREATE_DOCUMENT_REQUEST,
    CREATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_REQUEST,
    UPDATE_DOCUMENT_SUCCESS,
    UPDATE_DOCUMENT_ERROR,
    DELETE_DOCUMENT_SUCCESS,
    DELETE_DOCUMENT_ERROR,
    SET_DOCUMENTS_MODAL_VISIBILITY,
    SET_DOCUMENTS_LIST,
    SET_DELETE_MODAL_VISIBILITY,
} from 'redux/actions/types/documents';

const INITIAL_STATE = {
    isDocumentUploadButtonLoading: false,
    isDocumentModalVisible: false,
    isDeleteModalVisible: false,
    list: [],
    data: {},
    error: {}
};

const documentsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;
    switch (type) {

    case CREATE_DOCUMENT_REQUEST:
        return { 
            ...state, 
            isDocumentUploadButtonLoading: false,
        }

    case CREATE_DOCUMENT_SUCCESS: {
        let newList = [...state.list];

        let newUploadedDocument = {
            id: payload.id,
            appointmentId: payload.appointmentId,
            patientId: payload.patientId,
            fileName: payload.filename
        };

        newList.push(newUploadedDocument);

        return {
            ...state,
            list: newList,
            isDocumentUploadButtonLoading: false,
            isDocumentModalVisible: false,
        }
    }

    case CREATE_DOCUMENT_ERROR: {
        return {
            ...state,
            error,
        }
    }

    case UPDATE_DOCUMENT_REQUEST: {
        return {
            ...state,
            isDocumentUploadButtonLoading: true
        }
    }

    case UPDATE_DOCUMENT_SUCCESS: {
        return {
            ...state,
            isDocumentUploadButtonLoading: false
        }
    }

    case UPDATE_DOCUMENT_ERROR: {
        return {
            ...state,
            isDocumentUploadButtonLoading: false,
            error
        }
    }

    case DELETE_DOCUMENT_SUCCESS: {
        let newList = [...state.list];

        newList.filter(document => document.id !== action.id);

        return {
            ...state,
            list: newList
        }
    }

    case DELETE_DOCUMENT_ERROR: {
        return {
            ...state,
            error
        }
    }

    case SET_DOCUMENTS_MODAL_VISIBILITY: {
        return {
            ...state,
            isDocumentModalVisible: action.isDocumentModalVisible
        }
    }

    case SET_DOCUMENTS_LIST: {
        return {
            ...state,
            list: action.list
        }
    }
    
    case SET_DELETE_MODAL_VISIBILITY: {
        return {
            ...state,
            isDeleteModalVisible: action.isDeleteModalVisible
        }
    }

    default:
        return state
    }
};

export default documentsReducer;
