import React, { useState } from 'react';
import DynamicForm from 'components/DynamicForm';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { stagePatientUser } from 'api/users';
import { RootState } from 'redux/reducers';
import { FieldComponent, MessageTypes } from 'interfaces';
import { REGISTRATION_TYPE } from 'redux/types/tenant-types';
import MessagePage from 'components/MessagePage';

interface PatientDetailsProps {
    signupUri: string
}

export default (props: PatientDetailsProps) => {
    const [loading, setLoading] = useState(false);
    const { signupUri } = props;
    const tenantState = useSelector((state:RootState) => state.tenant);
    const isRegistrationPageNotStaging = tenantState?.registration?.type as REGISTRATION_TYPE !== REGISTRATION_TYPE.STAGING;

    return  <>
                {isRegistrationPageNotStaging 
                    && <MessagePage
                            type={MessageTypes.WARNING}
                            headingText={'Unauthorized'}
                            body={'Sorry, You are not authorized to access this page.'}
                        />}
                {!isRegistrationPageNotStaging && <DynamicForm
                    isSubmitButtonLoading={loading}
                    fields={tenantState?.registration?.fields as FieldComponent[]}
                    onSubmit={(values) => {
                        setLoading(true);
                        stagePatientUser({
                            dateOfBirth: values.dob.format('YYYY-MM-DD'),
                            firstName: values.firstName,
                            lastName: values.lastName,
                            mobileNumber: values.mobileNo
                        }).then(response => {
                            setLoading(false);
                            window.location.href = `${signupUri}/${response.data}`
                        }).catch(err => {
                            setLoading(false);
                            message.error(err.toString())
                        })
                    }}
                />}
            </>
}