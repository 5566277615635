import React, { FunctionComponent } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import {
  InputField,
  SelectField,
  DatePickerField,
} from "../../components/Controls";
import { Field, Form, Formik } from "formik";
import { Select, Button } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import * as Yup from "yup";
import UserSVG from "assets/svgs/user-profile.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "styles/Settings.scss";
import { setCurrentLanguage } from "../../redux/actions/i18n";
import { useDispatch } from "react-redux";
import { useAppSelector } from "./../../hooks/use-app-selector";

interface SettingsModuleProps {
  isOpen: boolean;
  handleClose: () => void;
}

const SettingsModule: FunctionComponent<SettingsModuleProps> = ({
  isOpen,
  handleClose,
}) => {
  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down(576)); // defined in styles/_responsive.scss
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useAppSelector((state) => state.permissions.permissions);

  return (
    <Dialog className="Settings-Dialog" open={isOpen} fullScreen={isFullScreen}>
      <div className="Settings-Wrapper h-100">
        <DialogTitle
          className="Settings-Title text-center"
          id="responsive-dialog-title"
        >
          <Container>
            <Row>
              <Col>
                <img
                  alt="profile"
                  className="Settings-UserProfile"
                  src={UserSVG}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <h2>Profile Settings</h2>
              </Col>
            </Row>
          </Container>
        </DialogTitle>
        <DialogContent className="Settings-Content bg-white">
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              dateOfBirth: moment(),
              contactNumber: "",
              email: "",
              preferredLanguage: "en",
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required(
                t("message.first_name_required")
              ),
              lastName: Yup.string().required(t("message.last_name_required")),
              dateOfBirth: Yup.string().required(
                t("message.date_of_birth_required")
              ),
              contactNumber: Yup.string().required(
                t("message.contact_no_required")
              ),
              email: Yup.string().required(t("message.email_required")),
              preferredLanguage: Yup.string().required(
                t("message.preferred_language_required")
              ),
            })}
            onSubmit={(values) => {
              i18n.changeLanguage(values.preferredLanguage);
              dispatch(setCurrentLanguage(values.preferredLanguage));
              handleClose();
            }}
          >
            <Form>
              <Container fluid>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      id="firstName"
                      name="firstName"
                      type="text"
                      label={t("label.first_name")}
                      placeholder={t("placeholder.first_name")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      id="lastName"
                      name="lastName"
                      type="text"
                      label={t("label.last_name")}
                      placeholder={t("placeholder.last_name")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label={t("label.date_of_birth")}
                      placeholder={t("placeholder.date_of_birth")}
                      component={DatePickerField}
                      size="large"
                    />
                  </Col>
                  <Col xs={12} md={6}>
                    <Field
                      id="contactNumber"
                      name="contactNumber"
                      type="text"
                      label={t("label.contact_number")}
                      placeholder={t("placeholder.contact_number")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      label={t("label.email")}
                      placeholder={t("placeholder.email")}
                      size="large"
                      component={InputField}
                    />
                  </Col>
                  {permissions.Settings?.includes(
                    "settings:update-preferred-language"
                  ) ? (
                    <Col xs={12} md={6}>
                      <Field
                        id="preferredLanguage"
                        name="preferredLanguage"
                        label={t("label.preferred_language")}
                        placeholder={t(
                          "placeholder.preferred_language_required"
                        )}
                        onChange={(value: any) => {
                          console.log(value);
                        }}
                        size="large"
                        style={{
                          width: "100%",
                          // zIndex: '9999'
                        }}
                        dropdownStyle={{
                          zIndex: 99999999,
                        }}
                        component={SelectField}
                      >
                        <Select.Option value="en">English</Select.Option>
                        <Select.Option value="fr">French</Select.Option>
                        <Select.Option value="ko">Korean</Select.Option>
                        <Select.Option value="ja">Japanese</Select.Option>
                      </Field>
                    </Col>
                  ) : null}
                </Row>
                <Row className="mt-5">
                  <Col>
                    <Button htmlType="submit" type="primary" block>
                      {t("label.save")}
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Button onClick={handleClose} block>
                      {t("label.cancel")}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Formik>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default SettingsModule;
