import React from 'react';
import { Avatar } from 'antd';
import { Row, Col, Container } from 'react-bootstrap';
import { MailOutline } from '@material-ui/icons';

import { useDispatch } from 'react-redux';
import {
    setNotificationAsRead,
} from 'redux/actions/notifications';
import {
    setNotificationsVisibility,
} from 'redux/actions/ui';
import { useHistory } from 'react-router';

const NotificationItem = ({ id, emailAddress, phoneNumber, subject, message, status }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    return (
        <div className={`Notification-Item ${status === 'UNREAD' &&  'Unread-Notification' }`} 
            onClick={() => {
                dispatch(setNotificationAsRead(id));
                dispatch(setNotificationsVisibility(false));
                history.push(`/inbox/${id}`);
            }}>
            <Container fluid>
                <Row>
                    <Col xs={2} md={2}>
                        <Avatar
                            size='large'
                            icon={<MailOutline />}
                            style={{
                                backgroundColor: '#74bbf8'
                            }}
                        />
                    </Col>
                    <Col xs={10} md={10}>
                        <h4 className='Message-Title'>{subject}</h4>
{/*
                        <h5><i><strong>From:</strong> {(phoneNumber ? phoneNumber : emailAddress)}</i></h5>
*/}
                        <h5 className='Message-Text'>{message}</h5>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default NotificationItem;