import React from 'react';
import { Select } from 'antd';
import { isFunction } from 'helpers/utils';

const SelectField = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
}) => {
    return (
        <>
            <label>{props.label}</label>
            <Select
                className={`Form-Dropdown ${touched[field.name] && errors[field.name] && 'Error'}`}
                {...field}
                {...props}
                placeholder='Select'
                onChange={(value) => {
                    if (isFunction(props.onChange)) {
                        props.onChange(value);
                    }
                    setFieldValue(field.name, value)
                }}
            >
                {props.children}
            </Select>
            {touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default SelectField;
