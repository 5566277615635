import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Steps } from 'antd';

import { useTheme } from '@material-ui/core/styles';

import 'styles/Onboarding.scss';
import ConfirmDetails from './ConfirmDetails';
import ConfirmAppointment from './ConfirmAppointment';

import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

const { Step } = Steps;

function OnBoardingModal(props) {
    const { currentActiveStep, isDialogVisible } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();
    
    const steps = [
        {
            title: t('label.confirm_details'),
            content: <ConfirmDetails />
        },
        {
            title: t('label.confirm_appointment'),
            content: <ConfirmAppointment />
        },
    ];

    return (
        <Dialog
            className='Onboarding-Dialog'
            fullScreen={fullScreen}
            fullWidth={true}
            open={isDialogVisible}
            aria-labelledby='responsive-dialog-title'
        >
            <DialogTitle id='responsive-dialog-title'>
                {t('label.get_started')}
            </DialogTitle>
            <DialogContent>
                <Steps 
                    className='Onboarding-Steps' 
                    size="small" 
                    current={currentActiveStep}
                >
                    {steps && steps.length > 0 && steps.map(step => (
                        <Step key={step.title} title={step.title} />
                    ))}
                </Steps>
                <div className='steps-content'>
                    {steps[currentActiveStep].content}
                </div>
            </DialogContent>
        </Dialog>
    );
}

export default connect(
    state => {
        return {
            isDialogVisible: state.onBoarding.isDialogVisible,
            currentActiveStep: state.onBoarding.currentActiveStep
        }
    }
)(OnBoardingModal);