import { 
    getNotificationsByPatientId,
    markNotificationAsRead,
    getUnreadNotificationsCount,
} from 'api/notifications';

import {
    FETCH_NOTIFICATIONS_LIST_ERROR,
    FETCH_NOTIFICATIONS_LIST_REQUEST,
    FETCH_NOTIFICATIONS_LIST_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS,
    FETCH_NOTIFICATIONS_LIST_TALLY_ERROR,
    MARK_NOTIFICATION_READ_SUCCESS,
    MARK_NOTIFICATION_READ_ERROR,
} from 'redux/actions/types/notifications';

export const fetchNotificationsListByUserId = (userId, currentPage = 0, pageSize = 0) => {
    return async dispatch => {
        try {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_REQUEST
            });

            let response;

            if (currentPage !== 0 && pageSize !== 0) {
                response = await getNotificationsByPatientId(userId, `&page=${currentPage}&size=${pageSize}`);
            } else {
                response = await getNotificationsByPatientId(userId);
            }

            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_SUCCESS,
                payload: response.data.data,
            });
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_ERROR,
                error
            });
        }
    }
};

export const setNotificationAsRead = id => {
    return async dispatch => {
        try {
            const response = await markNotificationAsRead(id);

            dispatch({
                type: MARK_NOTIFICATION_READ_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: MARK_NOTIFICATION_READ_ERROR,
                error,
            });
        }
    }
};

export const fetchNotificationsTallyByUserId = (userId) => {
    return async dispatch => {
        try {
            const response = await getUnreadNotificationsCount(userId);

            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_TALLY_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: FETCH_NOTIFICATIONS_LIST_TALLY_ERROR,
                error
            });
        }
    };
};