import React, { FunctionComponent } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Button, Modal } from 'antd'; 
import { useAppSelector } from './../../../hooks/use-app-selector';

import {
    RiCloseCircleLine
} from 'react-icons/all';

import {
    useTranslation
} from 'react-i18next';

interface DeclineModalProps {
    visible: boolean;
    appointmentName?: string;
    onOk: () => void;
    onCancel: () => void;
}

const DeclineModal: FunctionComponent<DeclineModalProps> = ({ visible, appointmentName, onOk, onCancel }) => {
    const appointments = useAppSelector(state => state.appointment);
    
    const { t } = useTranslation();

    return (
        <Modal
            title={appointmentName}
            visible={visible}
            footer={false}
            closable={false}
            centered
        >
            <Container fluid>
                <Row>
                    <Col className='text-center' xs={{
                        order: 1,
                        span: 12
                    }} md={{
                        order: 1,
                        span: 12
                    }}>
                        <RiCloseCircleLine fontSize='large' style={{ fontSize: '6rem', color: '#f44336' }} />
                        <h3>{t('message.decline_appointment')}</h3>
                    </Col>
                    <Col className='text-center' xs={{
                        order: 3,
                        span: 12
                    }} md={{
                        order: 2,
                        span: 6
                    }}>
                        <Button className='mb-4' onClick={onCancel} block loading={appointments.isConfirmationButtonLoading}>
                            {t('label.cancel')}
                        </Button>
                    </Col>
                    <Col className='text-center' xs={{
                        order: 2,
                        span: 12
                    }} md={{
                        order: 3,
                        span: 6
                    }}>
                        <Button className='mb-4' onClick={onOk} type='primary' block  loading={appointments.isConfirmationButtonLoading}>
                            {t('label.decline')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Modal>
    )
};

export default DeclineModal;
