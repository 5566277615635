import React, { FunctionComponent } from "react";
import Helmet from "react-helmet";

interface CollectionNoticeProps {
  title: string;
}

const CollectionNotice: FunctionComponent<CollectionNoticeProps> = (props) => {
  return (
    <>
      <Helmet>
        <style type="text/css">{`
        html, body {
            height: 100%;
            background-color: white !important;
        }
    `}</style>
      </Helmet>
      <div
        className="container"
        style={{
          height: "100vh",
          backgroundColor: "#FFF",
        }}
      >
        <h1 className="display-3">
          PRIVACY COLLECTION NOTICE AND CONSENT – PATIENTS
        </h1>
        <p className="text-justify">
            The Royal Prince Alfred Hospital is operated by Sydney LHD ABN 87 842 457 440  <strong>(The Royal Prince Alfred Hospital)</strong> andacknowledges the importance of patient privacy. We are committed to handling your information securely and to being open and transparent with you about our information handling processes.
        </p>
        <p className="text-justify">
            The UnitingCare Privacy Policy Statement is available{" "}
          <a href="http://wesley.com.au/quality-of-care/privacy-statement">
            here
          </a>
          . If you would prefer a printed version, please let us know by
          contacting us on (07) 3232 7594. Further details about how we deal
          with your personal information are provided below.
        </p>
        <p className="font-weight-bold">
            Who is collecting your personal information
        </p>
        <p>
            Your personal information is being collected by The Royal Prince Alfred Hospital. The contact details for The Royal Prince Alfred Hospital are:{" "}
        </p>
        <p className="ml-5">
            Address: Level 1, Building 2, Street 3, Brisbane  Qld  4076
        </p>
        <p className="ml-5">Telephone: (07) 3232 7594</p>
        <p className="font-weight-bold">
            Collection of your personal information
        </p>
        <p>Your personal information is collected:</p>
        <ol type="a">
            <li>
                from you when you provide personal information to us, including by
                completing admission forms, questionnaires and surveys, when
                observations are taken, when you report information to our staff
                about your health, and in some cases by way of photographs taken of
                you for a clinical purpose;
            </li>
            <li>
                from your relatives who may be able to provide us with information
                relevant to your healthcare where it is unreasonable or
                impracticable to collect information directly from you;
            </li>
            <li>
                from third party health service providers, including your doctors,
                diagnostic imaging and pathology companies;
            </li>
            <li>from Medicare, DVA and/or your health insurer; and</li>
            <li>from your My Health Record.</li>
        </ol>
        <p className="font-weight-bold">Authority for collection</p>
        <p className="text-justify">
            As a health service provider, we are required to collect and keep
            medical records of patients receiving services at our facilities.
        </p>
        <p className="font-weight-bold">
            Why does The Royal Prince Alfred Hospital collect your personal information?
        </p>
        <p className="text-justify">
            We collect your personal information in order to provide quality
            health services to you at our facility, and to conduct our follow-up
            telephone checks after you have been discharged. We also use the
            information for management of our services. This includes bookings,
            billings, audits, reminder letters, staff training, service planning and accreditation.
            We use de-identified information wherever practicable.
        </p>
        <p>
            We may also use your information to send you an SMS or email to remind
            you about your appointment.
        </p>
        <p className="font-weight-bold">
            What happens if you don't provide information to The Royal Prince Alfred?
        </p>
        <p className="text-justify">
            If The Royal Prince Alfred Hospital does not collect your personal information
            we may not be able to provide health care services to you. If we do
            not collect all of your relevant health information, this may pose a
            risk to your health as we will be using incomplete information to make
            care decisions. It may also impact on your ability to claim Medicare,
            DVA or private health insurance refunds.
        </p>
        <p className="font-weight-bold">
            Who will The Royal Prince Alfred Hospital disclose your personal information
            to?
        </p>
        <p className="text-justify">
            We disclose your health information for the purposes of providing a
            health service to you and managing that service. For example, we
            disclose your personal information to other third party health service
            providers (e.g. your doctor, relevant clinical registries, pharmacy
            services, diagnostic imaging and pathology companies) and our staff
            involved in your care (e.g. nurses and allied health) or providing
            administrative support. Information you provide to us via this service will be handled 
            on our behalf by Telstra Health Pty Ltd as our third party service provider. 
            Any personal information handled by Telstra Health will be managed in accordance with privacy obligations and with Telstra Health’s Privacy Policy, 
            available at <a href='https://telstrahealth.com'>https://telstrahealth.com</a>.
        </p>
        <p className="text-justify">
            We will ordinarily provide a report to your referring doctor 
            and other health practitioners involved in your care. 
            Please let us know if you do not want this to occur. 
            If you have received services at The Royal Prince Alfred Hospital as a public patient under an arrangement with a public hospital,
            we will provide a report to the public hospital that referred you.  
        </p>
        <p className="text-justify">
            We may also disclose information to your next-of-kin or 
            representative nominated on your admission if you do not have capacity 
            and we consider it necessary and appropriate for your ongoing care. 
        </p>
        <p className="text-justify">
            We disclose your personal information to Medicare, 
            DVA and your private health insurer for billing and regulatory purposes. 
            We are required to provide certain data to Government Health Departments about admissions to our facilities. 
            We will upload information to My Health Record unless you direct us not to.
        </p>
        <p>
            We do not disclose information to overseas recipients.
        </p>
        <p className="font-weight-bold">
            Access to and correction of your personal information
        </p>
        <p className="text-justify">
            The UnitingCare Privacy Policy statement contains information about 
            how you can seek access to and correction of personal information 
            about you that The Royal Prince Alfred Hospital holds.
        </p>
        <p className="font-weight-bold">Privacy complaints</p>
        <p className="text-justify">
            The UnitingCare Privacy Policy Statement contains information about 
            how you may complain about a breach of the <i>Privacy Act 1988</i> (Cth) and how The Wesley Breast 
            Clinic deals with these complaints.
        </p>
      </div>
    </>
  );
};

export default CollectionNotice;
