import {API_BASE_URL} from 'api/config';
import {createAxios} from "./axios";

// TODO temporary api to be used for resource summaries
const resourceApi = createAxios({
  baseURL: API_BASE_URL + '/collaterals'
});

export const fetchAll = params => {
  return resourceApi.get('', {params: params});
}

export const openCollateral = (id, type) => {
  return resourceApi.get(`/${id}/${type}`);
}