import { API_BASE_URL } from './config';
import {createAxios} from "./axios";

const patientUsers = createAxios({
    baseURL: API_BASE_URL + '/patient-users'
})

export const addUser = () => {
    return null;
}

export const stagePatientUser = payload => {
    return patientUsers.post('/staged', payload);
}

export const confirmPINPatientUser = (payload, uniqueId) => {
    return patientUsers.post(`/staged/${uniqueId}/confirmation-pin`, payload);
}

export const completeAddPatientUser = (payload, uniqueId) => {
    return patientUsers.post(`/staged/${uniqueId}/user-details`, payload);
}

export const regeneratePINPatientUser = (uniqueId) => {
    return patientUsers.get(`/staged/${uniqueId}/confirmation-pin`);
}

export const getUser = () => {
    return patientUsers.get('/me');
}

export const onboardUser = () => {
    return patientUsers.patch(`/me/onboarded`);
}