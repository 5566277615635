import React from 'react';

import { 
    Container,
    Row,
} from 'react-bootstrap';

import { useNode, useEditor } from '@craftjs/core';

const ContainerRow = (props) => {
    const { connectors: { connect, drag } } = useNode((state) => ({
        selected: state.events.selected
    }));

    const { query } = useEditor();

    return (
        <>
            <Container
                className={query.getOptions().enabled && 'With-Mark'} 
                ref={ref => {
                    connect(drag(ref));
                }}
                fluid
            >
                <Row {...props}>
                    {props.children}
                </Row>
            </Container>
        </>
    );
};

ContainerRow.craft = {
    displayName: 'Container'
};

export default ContainerRow;
