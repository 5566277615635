import {
    POST_TELEHEALTH_REQUEST,
    POST_TELEHEALTH_ERROR,
    POST_TELEHEALTH_SUCCESS,
} from 'redux/actions/types/zoom';

const INITIAL_STATE = {
    isTeleHealthButtonLoading: false,
    zoomLink: '',
    error: {},
}

const zoomReducer = (state = INITIAL_STATE, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case POST_TELEHEALTH_REQUEST:
            return { 
                ...state,
                isTeleHealthButtonLoading: true,
            }

        case POST_TELEHEALTH_SUCCESS: {
            return {
                ...state,
                isTeleHealthButtonLoading: false,
            }
        }

        case POST_TELEHEALTH_ERROR:
            return {
                ...state,
                isTeleHealthButtonLoading: false,
                error
            }

        default:
            return state
    }
}

export default zoomReducer;
