import React, { useEffect, useState } from "react";

import SideMenu from './SideMenu';
import SlickTopMenu from './SlickNav/SlickTopMenu';

import {
    handleResponsive,
} from 'helpers/utils';

const Navigation = (props) => {
    const [isSideMenuRendered, setSideMenuRender] = useState(false);

    useEffect(() => {
        handleResponsive({
            xsCallback: () => setSideMenuRender(false),
            lgCallback: () => setSideMenuRender(true)
        });
    }, [setSideMenuRender]);

    return <div role="navigation">
        <SlickTopMenu fullName={props.fullName} />
        {isSideMenuRendered && <SideMenu fullName={props.fullName} />}
    </div>
};

export default Navigation;
