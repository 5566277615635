import React from 'react';
import {MessageTypes} from 'interfaces';
import MessagePage from 'components/MessagePage';

export default () => {
    return  <MessagePage
                type={MessageTypes.WARNING}
                headingText={'Invalid Link'}
                body={<>
                    <span>Sorry, you have entered an invalid link.<br/>Please check you SMS for the latest link.</span><br/><br/>
                    <a href="/">Back to login</a>
                </>}
            />;
}