import React, { Component } from "react";
import Chart from "react-apexcharts";
import {getHeartrates} from "../../api/heartrates";
import {Col, Row} from "react-bootstrap";

class Heartrate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-bar",
                },
                xaxis: {
                    categories: [],
                },
            },
            series: [
                {
                    name: "Heart Rate",
                    data: [],
                },
            ],
        };
    }

    componentDidMount() {
        this.getData()
        setInterval(this.getData, 5000); // runs every 5 seconds.

    }

    getData = () => getHeartrates()
        .then((res) => {
            const categories = [];
            const data = [];

            console.log('res', res)
            res.data.forEach((item) => {
                data.push(item.heartRate);
                categories.push(item.timeStamp)

            });

            const newState = {
                options: {
                    chart: {
                        id: "basic-bar",
                    },
                    xaxis: {
                        categories,
                        title: {
                            text: "Date and Time of Reading",
                            style: {
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                cssClass: 'apexcharts-xaxis-title',
                            },
                        }
                    },
                    yaxis: {
                        categories,
                        title: {
                            text: "Heart Rate",
                            style: {
                                fontSize: '16px',
                                fontFamily: 'Helvetica, Arial, sans-serif',
                                fontWeight: 600,
                                cssClass: 'apexcharts-yaxis-title',
                            },
                        }
                    },
                    colors: ['#41B883'],
                    stroke: {
                        curve: 'smooth',
                    },
                    markers: {
                        size: [9]
                    },
                    dataLabels: {
                        style: {
                            fontSize: '12px',
                            fontWeight: 'bold',
                        },
                        background: {
                            enabled: true,
                            foreColor: '#fff',
                            borderRadius: 2,
                            padding: 4,
                            opacity: 0.9,
                            borderWidth: 1,
                            borderColor: '#fff'
                        },
                    },
                },
                series: [
                    {
                        name: "Heart Rate",
                        data,
                    },
                ],
            };

            this.setState(newState);
        });

    render() {
        return (
            <>
                <Row style={{height: '100%'}}>
                    <Col xs={12} md={12} lg={12} style={{height: '18px'}} className='mb-3'>
                        <div className="mixed-chart">
                            <Chart
                                options={this.state.options}
                                series={this.state.series}
                                type="line"
                                width="1300"
                                height="600"
                            />
                        </div>
                    </Col>
                </Row><br/>
            </>
        );

    }
}

export default Heartrate;
