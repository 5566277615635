import React from 'react';
import { CheckCircle, Info, Warning, Error } from '@material-ui/icons';
import { MessageTypes } from 'interfaces';

interface MessagePageProps {
    type: MessageTypes,
    headingText: string,
    body: string | React.ReactElement
}

export default (props: MessagePageProps) => {
    const { type, headingText, body } = props;

    const messageTypeIcons = {
        SUCCESS: {
            icon: <CheckCircle style={{ color: '#4caf50', fontSize: '10rem' }} />
        },
        INFO: {
            icon: <Info style={{ color: '#03a9f4', fontSize: '10rem' }} />
        },
        WARNING: {
            icon: <Warning style={{ color: '#ff9800', fontSize: '10rem' }} />
        },
        ERROR: {
            icon: <Error style={{ color: '#f44336', fontSize: '10rem' }} />
        }
    }

    return  <div style={{ marginTop: '30px', textAlign: 'center' }}>
                {messageTypeIcons[type]['icon']}<br/>
                <span style={{ fontWeight: 700, fontSize: '3.5rem' }}>{headingText}</span><br/>
                <span style={{ fontWeight: 500, fontSize: '2rem' }}>{body}</span><br/><br/>
            </div>;
}