import React, { useState, useEffect } from 'react';
import {
    Button,
    Input as AntInput,
    InputNumber,
    Checkbox,
    Radio,
    Tooltip,
    DatePicker,
    Select,
    Switch,
} from 'antd';

import moment from 'moment';

import { validateRequiredNode } from '../utils';

import {
    Row,
    Col
} from 'react-bootstrap';

import { useNode, useEditor } from '@craftjs/core';

import {
    FiTrash,
    FcInfo,
    HiExternalLink
} from 'react-icons/all';
import uuid from "react-uuid";
import EventSettings, {EventControlValues} from "../common/EventSettings";
import {evaluate} from "../common/EventPropertiesEvaluator";

import 'styles/Settings.scss';

const { Option } = Select;

const Input = ({
    type = 'text',
    withLabel = true,
    labelText = 'Label',
    error = {
        required: {
            isVisible: false,
            message: 'This field is required'
        }
    },
    isRequired,
    name,
    placeholder,
    radioItems,
    checkItem,
    hasTooltip = false,
    tooltipText = '',
    hasExternalLink = false,
    externalLinkUrl = '',
    picker = '',
    value = null,
    isDisabled = false,
    dateTimePickerFormat = 'DD-MM-YYYY',
    hasMultipleCheckbox = false,
    checkItems = [],
    isDecimal = false,
    decimalPlaces = 2
}) => {
    const {
        actions: { setProp },
        connectors: { connect, drag },
        eventProperties,
    } = useNode((state) => ({
        selected: state.events.selected,
        eventProperties: state.data.props.eventProperties
    }));

    const [dateTimePickerValue, setDateTimePickerValue] = useState(moment());
    const [inputValue, setInputValue] = useState(value);
    const [isFirstTimeValueSet, setFirstTimeValueSet] = useState(true);

    useEffect(() => {
        if (type === 'datetime' && value) {
            setDateTimePickerValue(moment(value));
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (value && isFirstTimeValueSet) {
            setFirstTimeValueSet(false);
            setInputValue(value);
        }
    }, [value]);

    useEffect(() => {
        let timeout = null;

        if (type !== 'checkbox') {
            timeout = setTimeout(() => {
                setProp(props => {
                    props.value = inputValue;
                });
            }, 500);
        } else if (type === 'checkbox' && hasMultipleCheckbox) {
            setProp(props => {
                props.value = inputValue
            });
        } else {
            if (type === 'checkbox' && value !== inputValue) {
                setProp(props => {
                    props.value = inputValue.toString();
                });
            }
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [inputValue]);

    useEffect(() => {
        if(eventProperties?.evaluateOnPageLoad && isFirstTimeValueSet && !query.getOptions().enabled) {
            let val;
            if(type === 'checkbox' && hasMultipleCheckbox) {
                val = value?.filter(val => val.value === 'true')
                    .map(val => val.displayText);
            } else {
                val = value;
            }
            evaluate(eventProperties, val, actions);
        }
    }, [eventProperties, value])

    const { actions, query } = useEditor();

    const onRadioButtonChange = e => {
        if (!query.getOptions().enabled) validateRequiredNode(e.target.value, setProp);
        evaluate(eventProperties, e.target.value, actions);
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginLeft: '20px'
    };

    const validateAndSetInputValue = inputControlValue => {
        setInputValue(inputControlValue);

        if (!inputControlValue) {
            setProp(props => {
                props.error.required.isVisible = true;
            });
        } else {
            setProp(props => {
                props.error.required.isVisible = false
            });
        }
    }

    const getDateValue = (dtValue) => {
        if (dtValue) {
            return moment(dtValue);
        } else {
            return moment()
        }
    }

    const numberInputType = (!query.getOptions().enabled && isDecimal) ? 'text' : 'number';
 
    return (
        <div
            className={query.getOptions().enabled && 'With-Mark'}
            ref={ref => connect(drag(ref))}
            style={{
                marginTop: '8px',
                marginBottom: '8px'
            }}
        >
            {withLabel && <>
                            <label style={{ marginTop: '0' }}>
                                {labelText}&nbsp;
                                {isRequired && <strong className='text-danger'>*</strong>}
                                {hasTooltip && <Tooltip title={tooltipText} placement='top'><FcInfo style={{ fontSize: '20px' }} /></Tooltip>}
                                {hasExternalLink && <HiExternalLink style={{ fontSize: '20px', color: '#0170FE' }} onClick={() => externalLinkUrl && window.open(externalLinkUrl) } />}
                            </label><br/>
                        </>}
            {type === 'text' && 
                <AntInput 
                    className={isRequired && error.required.isVisible && 'border border-danger'}
                    placeholder={placeholder} 
                    defaultValue={inputValue}
                    value={inputValue}
                    name={name}
                    onChange={ e => {
                        evaluate(eventProperties, e.target.value, actions);
                        
                        if (!query.getOptions().enabled) {
                            validateAndSetInputValue(e.target.value);
                        }
                    }}
                    onBlur={(e) => {
                        if (!query.getOptions().enabled) {
                            validateAndSetInputValue(e.target.value);
                        }
                    }}
                    size={'large'}
                    disabled={isDisabled}
                />}
            {type === 'checkbox' && 
                (!hasMultipleCheckbox ? <Checkbox 
                    name={name}
                    checked={((value === 'true'))}
                    onChange={ e => {
                        evaluate(eventProperties, e.target.checked, actions);
                        if (!query.getOptions().enabled) {
                            validateAndSetInputValue(e.target.checked);
                            setInputValue(e.target.checked);
                        }
                    }}
                    disabled={isDisabled}
                    size={'large'}
                >
                    {checkItem.displayText}
                </Checkbox> : (checkItems 
                    && checkItems.length > 0 
                    && checkItems.map((checkboxItem, idx) => {
                        return <>
                            <Checkbox 
                                name={`checkbox-${uuid()}-${idx}`}
                                checked={((value && value[idx] ? value[idx].value === 'true' : checkboxItem.value === 'true'))}
                                onChange={ e => {
                                    evaluate(eventProperties, e.target.checked, actions);
                                    if (!query.getOptions().enabled) {
                                        let checkItemsArray = [...checkItems];

                                        if (value && value.length > 0) {
                                            checkItemsArray = [...value];
                                        }

                                        let newCheckItems = [...checkItemsArray];

                                        let newCheckboxItem = {
                                            ...checkboxItem,
                                            value: e.target.checked.toString()
                                        };

                                        newCheckItems[idx] = newCheckboxItem;

                                        validateAndSetInputValue(newCheckItems);
                                        let val = newCheckItems?.filter(val => val.value === 'true')
                                            .map(val => val.displayText);
                                        evaluate(eventProperties, val, actions);
                                    }
                                }}
                                size={'large'}
                                style={{
                                    marginLeft: '20px'
                                }}
                                disabled={isDisabled}
                            >
                                {checkboxItem.displayText}
                            </Checkbox><br/>
                        </>
                    })))
            }
            {type === 'number' && 
                <input
                    className={`ant-input ant-input-lg ${isRequired && error.required.isVisible && 'border border-danger'}`}
                    value={inputValue}
                    placeholder={placeholder}
                    type={numberInputType}
                    onChange={e => {
                        let start = 0;
                        let numberValue = e.target.value;

                        if (isDecimal) {
                            start = e.target.selectionStart;
                            let val = e.target.value;
                            val = val.replace(/([^0-9.]+)/, '');
                            val = val.replace(/^(0|\.)/, '');
                            const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
                            const value = match[1] + match[2];
                            e.target.value = value;
                            numberValue = value;
                            if (val.length > 0) {
                                e.target.value = Number(value).toFixed(decimalPlaces);
                                e.target.setSelectionRange(start, start);

                                evaluate(eventProperties, numberValue, actions);

                                if (!query.getOptions().enabled) {
                                    validateAndSetInputValue(Number(value).toFixed(decimalPlaces));
                                }
                            }
                        } else {
                            evaluate(eventProperties, numberValue, actions);

                            if (!query.getOptions().enabled) {
                                validateAndSetInputValue(numberValue);
                            }
                        }
                    }}
                    onBlur={(e) => {
                        let numberValue = e.target.value;

                        if (isDecimal) {
                            const val = e.target.value;
                            numberValue = Number(val).toFixed(decimalPlaces);
                        }

                        if (!query.getOptions().enabled) {
                            validateAndSetInputValue(numberValue);
                        }
                    }} 
                    disabled={isDisabled}
                />
            }
            {type === 'password' && 
                <AntInput.Password 
                    name={name} 
                    onChange={ e => evaluate(eventProperties, e.target.value, actions) }
                />}
            {type === 'textarea' && 
                <AntInput.TextArea 
                    name={name} 
                    onChange={ e => evaluate(eventProperties, e.target.value, actions) }
                />}
            {type === 'radio' && (
                <Radio.Group 
                    name={name} 
                    onChange={onRadioButtonChange}
                    disabled={isDisabled}
                    value={value}
                >
                    {radioItems && radioItems.length > 0 && radioItems.map((radioItem) => (
                        <Radio
                            key={radioItem.key}
                            value={radioItem.value}
                            style={radioStyle}
                        >
                            {radioItem.displayText}&nbsp;
                            {radioItem.hasTooltip
                                && <Tooltip title={radioItem.tooltipText} placement='top'>
                                    <FcInfo style={{ fontSize: '20px' }} />
                                </Tooltip>}
                        </Radio>
                    ))}
                </Radio.Group>
            )}
            {type === 'datetime' && 
                <DatePicker 
                    className={isRequired && error.required.isVisible && 'border border-danger'}
                    value={getDateValue(dateTimePickerValue)}
                    picker={picker} 
                    style={{ width: '100%' }}
                    format={dateTimePickerFormat}
                    onChange={(value, dateString) => {
                        evaluate(eventProperties, value, actions);
                        setDateTimePickerValue(value);
                        if (!query.getOptions().enabled) validateRequiredNode(value, setProp);
                    }}
                    size={'large'}
                    disabled={isDisabled}
                />}
            <br/>
            {isRequired 
                && error 
                && error.required.isVisible 
                && <span className='validation-message text-danger'>{error.required.message}</span>
            }
        </div>
    );
};

const InputSettings = () => {
    const {
        actions: { setProp },
        placeholder,
        type,
        name,
        radioItems,
        checkItem,
        withLabel,
        labelText,
        isRequired,
        hasTooltip,
        tooltipText,
        hasExternalLink,
        externalLinkUrl,
        picker,
        error,
        isDisabled,
        dateTimePickerFormat = 'DD-MM-YYYY',
        hasMultipleCheckbox = false,
        checkItems = [],
        isDecimal = false,
        decimalPlaces = 2
    } = useNode((node) => ({
        placeholder: node.data.props.placeholder,
        name: node.data.props.name,
        type: node.data.props.type,
        radioItems: node.data.props.radioItems,
        withLabel: node.data.props.withLabel,
        labelText: node.data.props.labelText,
        isRequired: node.data.props.isRequired,
        checkItem: node.data.props.checkItem,
        hasTooltip: node.data.props.hasTooltip,
        tooltipText: node.data.props.tooltipText,
        hasExternalLink: node.data.props.hasExternalLink,
        externalLinkUrl: node.data.props.externalLinkUrl,
        picker: node.data.props.picker,
        error: node.data.props.error,
        isDisabled: node.data.props.isDisabled,
        dateTimePickerFormat: node.data.props.dateTimePickerFormat,
        hasMultipleCheckbox: node.data.props.hasMultipleCheckbox,
        checkItems: node.data.props.checkItems,
        isDecimal: node.data.props.isDecimal,
        decimalPlaces: node.data.props.decimalPlaces
    }));

    return (
        <>
            <label className='Form-Builder-Label'>Field name:</label>
            <AntInput
                placeholder='Field name'
                defaultValue={name}
                onChange={e => setProp(props => props.name = e.target.value)}
            /><br />
            {(type === 'text' || type === 'number') && (
                <>
                    <label className='Form-Builder-Label'>Placeholder:</label>
                    <AntInput
                        defaultValue={placeholder}
                        onChange={(e) => {
                            setProp(props => props.placeholder = e.target.value);
                        }}
                        style={{
                            width: '100%'
                        }}
                    />
                </>
            )}
            {(type === 'number') && (
                <>
                    <Row>
                        <Col xs={6}>
                            <span className='Settings-Switch-Label'>Has Decimal?</span>
                        </Col>
                        <Col xs={6} className='text-right'>
                            <Switch
                                className='Form-Builder-Checkbox'
                                checked={isDecimal}
                                onChange={(e) => setProp(props => props.isDecimal = e)}
                            />
                        </Col>
                        {isDecimal && <Col xs={12} style={{ marginBottom: '10px' }}>
                            <label className='Form-Builder-Label'>Decimal Places:</label>
                            <AntInput
                                defaultValue={decimalPlaces}
                                placeholder='Enter decimal places here'
                                onChange={(e) => setProp(props => props.decimalPlaces = e.target.value)}
                            />
                        </Col>}
                    </Row>
                </>
            )}
            <Row>
                <Col xs={6}>
                    <span className='Settings-Switch-Label'>Has Label?</span>
                </Col>
                <Col xs={6} className='text-right'>
                    <Switch
                        className='Form-Builder-Checkbox'
                        checked={withLabel}
                        onChange={(e) => setProp(props => props.withLabel = e)}
                    />
                </Col>
            </Row>
            {withLabel && (
            <>
                <label className='Form-Builder-Label'>Label Text:</label>
                <AntInput
                    defaultValue={labelText}
                    placeholder='Enter Label Text here'
                    onChange={(e) => setProp(props => props.labelText = e.target.value)}
                /><br/>
                <Row>
                    <Col xs={6}>
                        <span className='Settings-Switch-Label'>Required</span>
                    </Col>
                    <Col xs={6} className='text-right'>
                        <Switch
                            className='Form-Builder-Checkbox'
                            checked={isRequired}
                            onChange={(e) => setProp(props => props.isRequired = e)}
                        />
                    </Col>
                </Row>
            </>)}
            {isRequired && (
            <>
                <label className='Form-Builder-Label'>Required Error message:</label>
                <AntInput
                    defaultValue={error.required.message}
                    placeholder='Enter Required error message'
                    onChange={(e) => setProp(props => props.error.required.message = e.target.value)}
                /><br/>
            </>
            )}
            <Row>
                <Col xs={6}>
                    <span className='Settings-Switch-Label'>Has Tooltip?</span>
                </Col>
                <Col xs={6} className='text-right'>
                    <Switch
                        className='Form-Builder-Checkbox'
                        checked={hasTooltip}
                        onChange={(e) => setProp(props => props.hasTooltip = e)}
                    />
                </Col>
            </Row>
            {hasTooltip && <><label className='Form-Builder-Label'>Tooltip Text:</label>
            <AntInput
                defaultValue={tooltipText}
                placeholder='Enter Tooltip Text here'
                onChange={(e) => setProp(props => props.tooltipText = e.target.value)}
            /><br/><br/></>}
            <Row>
                <Col xs={6}>
                    <span className='Settings-Switch-Label'>Has External link?</span>
                </Col>
                <Col xs={6} className='text-right'>
                    <Switch
                        className='Form-Builder-Checkbox'
                        checked={hasExternalLink}
                        onChange={(e) => setProp(props => props.hasExternalLink = e)}
                    />
                </Col>
            </Row>
            {hasExternalLink && <><label className='Form-Builder-Label'>External Link URL:</label>
            <AntInput
                defaultValue={externalLinkUrl}
                placeholder='https://fivefaces.com.au'
                onChange={(e) => setProp(props => props.externalLinkUrl = e.target.value)}
            /><br/></>}
            {type === 'datetime' && (
                <>
                    <Row>
                        <Col xs={12}>
                            <label className='Form-Builder-Label'>Date Picker Type:</label><br />
                            <Select defaultValue={picker} onChange={value => setProp(props => props.picker = value)} style={{ width: '100%' }}>
                                <Option value=''>Date</Option>
                                <Option value='week'>Week</Option>
                                <Option value='month'>Month</Option>
                                <Option value='quarter'>Quarter</Option>
                                <Option value='year'>Year</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <label className='Form-Builder-Label'>Format:</label><br />
                            <AntInput
                                defaultValue={dateTimePickerFormat}
                                placeholder={'Date Format here'}
                                onChange={e => setProp(props => props.dateTimePickerFormat = e.target.value)}
                            />
                        </Col>
                    </Row>
                </>
            )}
            {type === 'radio' && (
                <>
                    <Row>
                        <Col xs={12}>
                            <label className='Form-Builder-Label'>Radio Items:</label>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={5}>
                            <label className='Form-Builder-Label'>Value</label>
                        </Col>
                        <Col xs={5}>
                            <label className='Form-Builder-Label'>Display Text</label>
                        </Col>
                        <Col xs={2}>
                        </Col>
                    </Row>
                    {radioItems && radioItems.length > 0 && radioItems.map((radioItem) => (
                        <Row key={radioItem.key}>
                            <Col xs={5}>
                                <AntInput
                                    key={radioItem.key}
                                    placeholder='Value'
                                    defaultValue={radioItem.value}
                                    style={{
                                        marginBottom: '0'
                                    }}
                                    onChange={ e => {
                                        setProp(props => {
                                            const radio = props.radioItems.filter(item => item.key == radioItem.key)[0];
                                            radio.value = e.target.value;
                                        })
                                    }}
                                />
                                <Checkbox
                                    className='Form-Builder-Checkbox'
                                    checked={radioItem.hasTooltip}
                                    onChange={(e) => {
                                        setProp(props => {
                                            const radio = props.radioItems.filter(item => item.key == radioItem.key)[0];
                                            radio.hasTooltip = e.target.checked;
                                        })
                                    }}
                                    style={{
                                        marginTop: '-5px'
                                    }}
                                >
                                    <small>Has Tooltip?</small>
                                </Checkbox><br />
                            </Col>
                            <Col xs={5}>
                                <AntInput
                                    key={radioItem.key}
                                    placeholder='Display Text'
                                    defaultValue={radioItem.displayText}
                                    style={{
                                        marginBottom: '10px'
                                    }}
                                    onChange={ e => {
                                        setProp(props => {
                                            const radio = props.radioItems.filter(item => item.key == radioItem.key)[0];
                                            radio.displayText = e.target.value;
                                        })
                                    }}
                                />
                                {radioItem.hasTooltip && <AntInput
                                    key={radioItem.key}
                                    placeholder='Tooltip Text'
                                    defaultValue={radioItem.tooltipText}
                                    style={{
                                        marginBottom: '10px'
                                    }}
                                    onChange={ e => {
                                        setProp(props => {
                                            const radio = props.radioItems.filter(item => item.key == radioItem.key)[0];
                                            radio.tooltipText = e.target.value;
                                        })
                                    }}
                                />}
                            </Col>
                            <Col xs={2}>
                                <FiTrash onClick={ e => {
                                    setProp( props => {
                                        const newItems = props.radioItems.filter( item => item.key != radioItem.key);
                                        props.radioItems = newItems;
                                    })
                                }} />
                            </Col>
                        </Row>
                    ))}
                    <Row>
                        <Col>
                            <Button
                                type='link'
                                onClick={ e => {
                                    setProp( props => {
                                        const len = props.radioItems.length + 1;
                                        props.radioItems.push({
                                            key: uuid(),
                                            displayText: `Radio ${len}`,
                                            value: `Value${len}`
                                        })
                                    })
                                }}>+ Add new Item</Button>
                        </Col>
                    </Row>
                </>
            )}
            {type === 'checkbox' && (
                <>
                    <Row>
                        <Col xs={6}>
                            <span className='Settings-Switch-Label'>Is Multiple?</span>
                        </Col>
                        <Col xs={6} className='text-right'>
                            <Switch
                                className='Form-Builder-Checkbox'
                                checked={hasMultipleCheckbox}
                                onChange={(e) => setProp(props => props.hasMultipleCheckbox = e)}
                            />
                        </Col>
                    </Row>
                    {hasMultipleCheckbox && (
                        <Row>
                            <Col xs={12}>
                                <label className='Form-Builder-Label'>Checkbox Items</label>
                            </Col>
                        </Row>
                    )}
                    {hasMultipleCheckbox 
                        && checkItems 
                        && checkItems.length > 0 
                        && checkItems.map((checkboxItem) => (
                            <>
                                <Row key={checkboxItem.key}>
                                    <Col xs={5}>
                                        <AntInput
                                            key={checkboxItem.key}
                                            placeholder='Value'
                                            defaultValue={checkboxItem.value}
                                            style={{
                                                marginBottom: '0'
                                            }}
                                            onChange={ e => {
                                                setProp(props => {
                                                    const currentCheckItem = props.checkItems.filter(item => item.key == checkboxItem.key)[0];
                                                    currentCheckItem.value = e.target.value;
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col xs={5}>
                                        <AntInput
                                            key={checkboxItem.key}
                                            placeholder='Display Text'
                                            defaultValue={checkboxItem.displayText}
                                            style={{
                                                marginBottom: '10px'
                                            }}
                                            onChange={ e => {
                                                setProp(props => {
                                                    const currentCheckItem = props.checkItems.filter(item => item.key == checkboxItem.key)[0];
                                                    currentCheckItem.displayText = e.target.value;
                                                })
                                            }}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <FiTrash onClick={ e => {
                                            setProp( props => {
                                                const newItems = props.checkItems.filter( item => item.key != checkboxItem.key);
                                                props.checkItems = newItems;
                                            })
                                        }} />
                                    </Col>
                                </Row>
                            </>
                    ))}
                    {hasMultipleCheckbox && (
                        <Row>
                            <Col>
                                <Button
                                    type='link'
                                    onClick={ e => {
                                        setProp( props => {
                                            let newCheckItems = [];
                                            let len = 0;

                                            if (!props.checkItems) {
                                                len += 1;

                                                newCheckItems = [{
                                                    key: uuid(),
                                                    displayText: `Checkbox ${len}`,
                                                    value: false
                                                }];
                                            } else {
                                                len = props.checkItems.length + 1;

                                                newCheckItems = props.checkItems;
                                                newCheckItems.push({
                                                    key: uuid(),
                                                    displayText: `Checkbox ${len}`,
                                                    value: false
                                                });
                                            }

                                            props.checkItems = newCheckItems;
                                        });
                                    }}>+ Add new Item</Button>
                            </Col>
                        </Row>
                    )}
                    {!hasMultipleCheckbox && 
                        <>
                            <Row>
                                <Col>
                                    <label className='Form-Builder-Label'>Display text</label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AntInput
                                        placeholder='Display Text'
                                        value={checkItem.displayText}
                                        onChange={ e => {
                                            setProp( props => {
                                                props.checkItem.displayText = e.target.value;
                                            })
                                        }}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </>
            )}
        </>
    )
};

const InputEventSettings = () => {
    return <EventSettings />
}

Input.craft = {
    displayName: 'Input',
    props: {
        name: 'input',
        placeholder: 'Placeholder',
        radioItems: [
            {
                key: uuid(),
                value: 'Value1',
                displayText: 'Radio 1',
                hasTooltip: false,
                tooltipText: '',
            },
            {
                key: uuid(),
                value: 'Value2',
                displayText: 'Radio 2',
                hasTooltip: false,
                tooltipText: '',
            },
            {
                key: uuid(),
                value: 'Value3',
                displayText: 'Radio 3',
                hasTooltip: false,
                tooltipText: '',
            }
        ],
        type: 'text',
        withLabel: true,
        labelText: 'Label',
        isRequired: false,
        checkItem: {
            isChecked: true,
            displayText: 'Checkbox'
        },
        hasTooltip: false,
        tooltipText: '',
        hasExternalLink: false,
        externalLinkUrl: '',
        picker: '',
        eventProperties: {
            conditionalEventEnabled: false,
            action: EventControlValues.actions[0],
            selectedControls: [],
            conditions: [],
            evaluateOnPageLoad: false
        },
        error: {
            required: {
                isVisible: false,
                message: 'This field is required'
            }
        },
        value: null
    },
    related: {
        settings: InputSettings,
        eventSettings: InputEventSettings
    }
};

export default Input;
