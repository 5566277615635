import React, {useEffect, useState, createRef} from 'react';

import 'styles/FormBuilder.scss';

import CraftEditorInitializer from 'pages/FormBuilder/components/CraftEditorInitializer';
import Button from 'pages/FormBuilder/components/Button';
import Card from 'pages/FormBuilder/components/Card';
import Text from 'pages/FormBuilder/components/Text';
import Container from 'pages/FormBuilder/components/Container';
import Root from 'pages/FormBuilder/components/Root';
import Input from 'pages/FormBuilder/components/Input';
import Panel from 'pages/FormBuilder/components/Panel';
import Select from 'pages/FormBuilder/components/Select';
import Column from 'pages/FormBuilder/components/Column';
import ContainerRow from 'pages/FormBuilder/components/ContainerRow';

import {
    Editor,
    Frame,
    Element,
    useEditor
} from "@craftjs/core";

import lz from 'lzutf8';

import { fetchFormDesignById } from "../../api/formDesigns";

import {
    getFormSubmission
} from 'api/formSubmissions';

import {
    message
} from 'antd';

const FormBuilder = ({ 
    id, 
    activeSubmissionFormId = 0,
    setFormDesignData,
    isFieldsDisabled
}) => {
    const [jsonContent, setJsonContent] = useState(null);
    const [formSubmissionContent, setFormSubmissionContent] = useState([]);

    const requestFormContent = async () => {
        try {
            const response = await getFormSubmission(activeSubmissionFormId);
            const formSubmissionContentResponse = response.data.content;
            if (formSubmissionContentResponse) {
                setFormSubmissionContent(JSON.parse(formSubmissionContentResponse));
            }
        } catch (error) {
            console.log(error);
            message.error('Error occured while building the form');
        }
    }

    useEffect(() => {
        let isMounted = true;
        (async () => {
            let data = await fetchFormDesignById(id);
            if (isMounted) {
                let content = data.data.content;
                setFormDesignData(data.data);
                let form = lz.decompress(lz.decodeBase64(content));
                setJsonContent(form);
                requestFormContent();
            }
        })()

        return () => {
            isMounted = false
        }
    }, []);

    return (
        <>
            <Editor
                resolver={{
                    Root,
                    Card,
                    Button,
                    Container,
                    Text,
                    Input,
                    Panel,
                    Select,
                    Column,
                    ContainerRow
                }} 
                enabled={false}>
                <div>
                    {jsonContent && <><Frame data={jsonContent}>
                        <Element canvas={true} is={Container}>
                        </Element>
                    </Frame></>}
                    <CraftEditorInitializer
                        formSubmissionContent={formSubmissionContent}
                        isFieldsDisabled={isFieldsDisabled}
                    />
                </div>
            </Editor>
        </>
    );
};

export default FormBuilder;
