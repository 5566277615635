import {
    ADD_USER_REQUEST,
    ADD_USER_SUCCESS,
    ADD_USER_ERROR
} from "../actions/types/users";

const INITIAL_STATE = {
    isLoading: false,
    data: null,
    error: null
}

const user = (state = INITIAL_STATE, action) => {
    const {type, payload, error} = action;
    switch (type) {
        case ADD_USER_REQUEST: {
            return {
                ...state,
                isLoading: true,
                data: null,
                error: null,
            }
        }

        case ADD_USER_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: payload,
                error: null,
            }
        }

        case ADD_USER_ERROR: {
            return {
                ...state,
                isLoading: false,
                data: null,
                error: error.message
            }
        }

        default:
            return state;
    }
}

export default user;