import React, {useEffect, useState} from 'react';
import {Modal, Table} from 'antd';
import {FileTextOutlined} from '@ant-design/icons';

import 'styles/Attachments.scss';

import {Row} from 'react-bootstrap';
import Card from 'components/form/components/Card';

import {Link} from "react-router-dom";
import styled from 'styled-components';
import {fetchAppointmentFormsDataTable} from "api/formSubmissions";
import {useParams} from "react-router";
import {useMediaQuery} from "@material-ui/core";
import {useTheme} from '@material-ui/core/styles';
import FormSubmitModal from "./FormSubmitModal";
import moment from 'moment';

import 'styles/RowHighlights.scss';

const StyledModal = styled(Modal)`
    & .ant-modal-body {
        max-height: 600px !important;
        overflow: auto !important;
    }

    @media(max-width: 991px) {
        width: 100% !important;
    }
`

const StyledTable = styled(Table)`
    width: 100%;
    background: #fff;
    & .ant-spin-nested-loading,
    & .ant-spin-container {
        height: 100%;
    }

    & .ant-table {
        height: 100%;
    }

    & .ant-pagination {
        position: relative;
        top: -58px;
        margin: 16px;
    }

    & .anticon-file-text {
        vertical-align: unset;
    }
`

function FormList(props) {
    const [isFormModalVisible, setFormModalVisibility] = useState(false);
    const [formEntity, setFormEntity] = useState(null);
    const [title, setTitle] = useState();
    const [forms, setForms] = useState([]);
    const [activeSubmissionFormId, setActiveSubmissionFormId] = useState(null);
    const [activeFormDesignId, setActiveFormId] = useState(null);
    const [isEditorModalLoading, setIsEditorModalLoading] = useState(false);
    const [willFormListRefresh, setWillFormListRefresh] = useState(false);
    const [draw, setDraw] = useState(0);
    const [formStatus, setFormStatus] = useState(null);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const FORM_PAGE_SIZE = 5;

    const col = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, index) => <Link onClick={() => {
                setActiveFormId(record.formDesign.id);
                setActiveSubmissionFormId(record.id);
                setTitle(record.name);
                setFormModalVisibility(true);
                setFormStatus(record.status);
            }}>
                {text}
                <small 
                    className={'font-weight-normal text-muted'}
                    style={{ marginLeft: '17px' }}
                >
                    {moment(record.lastModifiedAt).format('DD/MM/YYYY hh:mm A')}  
                </small>
            </Link>,
        }
    ]

    const param = useParams();

    useEffect(() => {
        (async () => {
            let { data } = await fetchAppointmentFormsDataTable(param.id, {
                draw: 1,
                page: 0,
                length: 10000,   // temp
                sortColumn: 'lastModifiedAt',
                sortDirection: 'DESC'
            });

            setForms(data.data.map(elem => elem));
        })();
    }, []);

    useEffect(() => {
        if (draw) {
            (async () => {
                let { data } = await fetchAppointmentFormsDataTable(param.id, {
                    draw: draw,
                    page: 0,
                    length: 10000,   // temp
                    sortColumn: 'id',
                    sortDirection: 'ASC'
                });
    
                setForms(data.data.map(elem => elem));
            })();
        }
    }, [draw]);

    return (
        <>
            <FormSubmitModal
                visible={isFormModalVisible}
                setVisible={setFormModalVisibility}
                fullScreen={fullScreen}
                activeSubmissionId={activeSubmissionFormId}
                designId={activeFormDesignId}
                formStatus={formStatus}
                name={title}
                formDraw={draw}
                setFormDraw={setDraw}
            />
            <StyledTable
                columns={col}
                pagination={{
                    pageSize: FORM_PAGE_SIZE,
                }}
                dataSource={forms.map((form, i) =>
                    (
                        {
                            ...form,
                            name: <>
                                    <div key={i}>
                                        <FileTextOutlined /> { form.formDesign.name }<br/>
                                    </div>
                                </>
                        }
                    )
                )}
                rowClassName={(record, i) =>
                    record.highlight? 'Row-Highlight': 'Row-Unhighlight'}
            />
        </>
    );
}

export default FormList;