import React from 'react';
import { DatePicker } from 'antd';
import { isFunction } from 'helpers/utils';

const DatePickerField = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
}) => {
    return (
        <>
            <label>{props.label}</label>
            <DatePicker
                className={`Form-DatePicker ant-datepicker ${touched[field.name] && errors[field.name] && 'Error'}`}
                {...field}
                {...props}
                onChange={(value) => {
                    if (isFunction(props.onChange)) {
                        props.onChange(value);
                    }
                    if(value === null) {
                        value = '';
                    }
                    setFieldValue(field.name, value)
                }}
                format='DD-MM-YYYY'
            />
            {touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default DatePickerField;
