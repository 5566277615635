import React, {FunctionComponent, useState} from "react";
import {Calendar} from "antd";
import moment from "moment";

interface ReferralBookingPageProps {

}

const ReferralBookingPage: FunctionComponent<ReferralBookingPageProps> = (props) => {

    const [selectedDate, setSelectedDate] = useState(moment());

    const availabilities = [
        {
            date: '2021-06-17',
            slots: 8
        },
        {
            date: '2021-06-18',
            slots: 3
        },
        {
            date: '2021-06-24',
            slots: 5
        },
        {
            date: '2021-06-29',
            slots: 7
        }
    ]


    return <Calendar onSelect={(date) => setSelectedDate(date)} disabledDate={(currentDate) => moment().isAfter(currentDate)} dateCellRender={value => {
        const availability = availabilities.find(a => {
            return a.date === value.format("YYYY-MM-DD")
        })
        if (availability) {
            return <div style={{
                marginTop: "-10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}><div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "start",
                color: selectedDate.format("YYYY-MM-DD") === value.format("YYYY-MM-DD") ? '#fff' : '#8e44ad',
                fontSize: "30px"
            }}><p style={{
                marginBottom: 0
            }}>{availability.slots}</p><p style={{
                fontSize: "15px"
            }}>appointments available</p></div></div>
        }
    }} />
}

export default ReferralBookingPage

