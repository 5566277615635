import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import 'styles/Messages.scss';
import 'styles/RowHighlights.scss';

import { List, Avatar, Spin, Tag } from 'antd';
import { MailOutline, Drafts } from '@material-ui/icons';
import { Container, Row, Col } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import {
    setNotificationAsRead,
} from 'redux/actions/notifications';
import {
    fetchMessagesByUserId,
} from 'redux/actions/messages';

import { handleResponsive } from 'helpers/utils';
import { getUser } from 'api/users';

const Inbox = () => {
    const [paginationPosition, setPaginationPosition] = useState('bottom');
    const userId = 0;
    const defaultPage = 0;
    const defaultPageSize = 10;

    const history = useHistory();
    const messageState = useSelector(state => state.messages);
    const dispatch = useDispatch();

    const { list, totalElements, isInboxListLoading } = messageState;
    const messageList = list;
    const [ currentUser, setCurrentUser ] = useState(null);

    useEffect(() => {
        handleResponsive({
            xsCallback: () => setPaginationPosition('top'),
            lgCallback: () => setPaginationPosition('bottom')
        });

        if(currentUser) {
            dispatch(fetchMessagesByUserId(currentUser?.id, defaultPage, defaultPageSize));
        } else {
            fetchCurrentUser(); 
        }
    }, [userId, dispatch, currentUser]);

    const fetchCurrentUser = async () => {
        const res = await getUser();
        setCurrentUser(res.data);   
    }

    const handleMessageListItemClick = (id) => {
        dispatch(setNotificationAsRead(id));
        history.push(`/inbox/${id}`);
    };

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Spin spinning={isInboxListLoading}>
                        <List
                            className='Inbox-List-Container'
                            itemLayout='horizontal'
                            dataSource={messageList}
                            pagination={{
                                position: paginationPosition,
                                total: totalElements,
                                pageSize: defaultPageSize,
                                onChange: (page, pageSize) => {
                                    dispatch(fetchMessagesByUserId(currentUser?.id, page - 1, defaultPageSize));
                                },
                            }}
                            showSizeChanger
                            renderItem={(message, i) => (
                                <List.Item
                                    className={`Inbox-Item ${ message.highlight? 'Row-Highlight': 'Row-Unhighlight' }`}
                                    extra={<span>{moment().format('DD/MM')}</span>}
                                    onClick={() => handleMessageListItemClick(message.id)}
                                >
                                    <List.Item.Meta
                                        avatar={<Avatar
                                            size='large'
                                            icon={message.status === 'READ' ? <Drafts /> : <MailOutline />}
                                            style={{
                                                backgroundColor: '#74bbf8'
                                            }}
                                        />}
                                        title={<Link to={'/inbox'}>
                                            {message.replyingToMessage? 'Re: ': null}
                                                {message.subject}
                                                <div>
                                                { message.sender === 'STAFF' ?
                                                    <Tag color='blue'>Incoming</Tag>
                                                    :
                                                    <Tag color='green'>Outgoing</Tag>
                                                }
                                                </div>
                                            </Link>}
                                        description={message.message}
                                    />
                                </List.Item>
                            )}
                        />
                    </Spin>
                </Col>
            </Row>
        </Container>
    );
};

export default Inbox;