import {
    SET_ONBOARDING_CURRENT_ACTIVE_STEP,
    PREVIOUS_STEP,
    NEXT_STEP,
    SET_ONBOARDING_MODAL_VISIBILITY,
} from 'redux/actions/types/onBoarding';

const INITIAL_STATE = {
    currentActiveStep: 0,
    stepLength: 2,
    isDialogVisible: true,
};

const onBoardingReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case SET_ONBOARDING_MODAL_VISIBILITY:
            return {
                ...state,
                isDialogVisible: action.isDialogVisible
            }

        case SET_ONBOARDING_CURRENT_ACTIVE_STEP: 
            return {
                ...state,
                currentActiveStep: action.step
            }

        case PREVIOUS_STEP: {
            const { currentActiveStep } = state;

            const currentStep = (currentActiveStep + 1 > 0 ? currentActiveStep - 1 : currentActiveStep);

            return {
                ...state,
                currentActiveStep: currentStep
            }
        }

        case NEXT_STEP: {
            const { currentActiveStep, stepLength } = state;

            const currentStep = (currentActiveStep + 1 < stepLength ? currentActiveStep + 1 : currentActiveStep);

            return {
                ...state,
                currentActiveStep: currentStep
            }
        }
        default:
            return state;
    }
};

export default onBoardingReducer;