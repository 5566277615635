import React from 'react';
import { Input } from 'antd';

const InputField = ({
    field,
    form: { touched, errors },
    meta,
    ...props
}) => {
    return (
        <>
            <label>{props.label}</label>
            <Input
                className={`Form-Input ${touched[field.name] && errors[field.name] && 'Error'}`}
                {...props}
                {...field}
            />
            {touched[field.name] && errors[field.name] && (
                <p className='text-danger Error-Text'>{errors[field.name]}</p>
            )}
        </>
    )
};

export default InputField;
