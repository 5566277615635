import {
    TOGGLE_SIDEBAR_VISIBILITY,
    SET_STATUS_MODAL_VISIBILITY,
    SET_MESSAGE_MODAL_VISIBILITY,
    SET_COLLATERAL_MODAL_VISIBILITY,
    SET_NOTE_MODAL_VISIBILITY,
    SET_TOUR_VISIBILITY,
    SET_NOTIFICATIONS_VISIBILITY,
    SET_PAGE_TITLE,
    SET_BREADCRUMBS_TEXT,
} from 'redux/actions/types/ui';

const INITIAL_STATE = {
    isSidebarVisible: true,
    isStatusModalVisible: false,
    isMessageModalVisible: false,
    isCollateralModalVisible: false,
    isNoteModalVisible: false,
    isTourVisible: false,
    isNotificationsVisible: false,
    pageTitle: '',
    currentBreadcrumbDisplay: ''
};

const uiReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case TOGGLE_SIDEBAR_VISIBILITY: {
            const isSidebarVisible = (state.isSidebarVisible ? false : true);

            return {
                ...state,
                isSidebarVisible,
            }
        }

        case SET_STATUS_MODAL_VISIBILITY: {
            return {
                ...state,
                isStatusModalVisible: action.isStatusModalVisible,
            }
        }

        case SET_MESSAGE_MODAL_VISIBILITY: {
            return {
                ...state,
                isMessageModalVisible: action.isMessageModalVisible,
            }
        }

        case SET_COLLATERAL_MODAL_VISIBILITY: {
            return {
                ...state,
                isCollateralModalVisible: action.isCollateralModalVisible,
            }
        }

        case SET_NOTE_MODAL_VISIBILITY: {
            return {
                ...state,
                isNoteModalVisible: action.isNoteModalVisible,
            }
        }

        case SET_TOUR_VISIBILITY: {
            return {
                ...state,
                isTourVisible: action.isTourVisible,
            }
        }

        case SET_NOTIFICATIONS_VISIBILITY: {
            return {
                ...state,
                isNotificationsVisible: action.isNotificationsVisible
            }
        }

        case SET_PAGE_TITLE: {
            return {
                ...state,
                pageTitle: action.pageTitle
            }
        }

        case SET_BREADCRUMBS_TEXT: {
            return {
                ...state,
                currentBreadcrumbDisplay: action.currentBreadcrumbDisplay
            }
        }

        default:
            return state;
    }
};

export default uiReducer;
