import React, {useEffect} from 'react';
import {useEditorSelector} from "./components/Selector";

const ControlSelector = ({id, onSelect, enabled }) => {
    const { actions: { setSelector }, selector } = useEditorSelector(selected => {
        onSelect(selected);
    }, id);

    useEffect(() => {
        selector.enabled = enabled
        setSelector(selector);
    }, [enabled])

    return null;
}

export default ControlSelector;